import Vue from 'vue';
import Vuex from 'vuex';

import SmartSuiteSecurityPlugin from "@/plugins/smartsuite-security-plugin";

Vue.use(Vuex);

let authStore = new Vuex.Store({
    state: {
        email: null,
        email_verified: null,
        modulegrants: null,
        featuregrants: null,
        role: null,
        segmentgrants: null,
        eventgrants: null,
        sub: null,
        name: null,
        homeTenantId: null,
        profilePicture: null,
        userManager: new SmartSuiteSecurityPlugin()
    },
    mutations: {
        updateUser(state, user) {
            state.email = user.profile.email;
            state.email_verified = user.profile.email_verified;
            state.modulegrants = user.profile.modulegrants;
            state.featuregrants = user.profile.featuregrants;
            state.eventgrants = user.profile.eventgrants;
            state.profilePicture = user.profile.picture;
            state.role = user.profile.role;
            state.name = user.profile.username;
            state.homeTenantId = user.profile.locality;
            if (user.profile.segmentgrants && Array.isArray(user.profile.segmentgrants)) {
                state.segmentgrants = user.profile.segmentgrants.map(segment => {
                    return {
                        tenantName: segment.split('|')[0],
                        _id: segment.split('|')[1]
                    }
                });
            } else if (user.profile.segmentgrants) {
                state.segmentgrants = [{
                        /*_id: user.profile.segmentgrants.split('|')[0],
                        url: user.profile.segmentgrants.split('|')[1]*/
                        tenantName: user.profile.segmentgrants.split('|')[0],
                        _id: user.profile.segmentgrants.split('|')[1]
                    }];
            } else {
                state.segmentgrants = [];
            }
            if (user.profile.eventgrants && Array.isArray(user.profile.eventgrants)) {
                state.eventgrants = user.profile.eventgrants.map(segment => {
                    return {
                        _id: segment.split('|')[0],
                        url: segment.split('|')[1]
                    }
                });
            } else if (user.profile.eventgrants) {
                state.eventgrants = [{
                        _id: user.profile.eventgrants.split('|')[0],
                        url: user.profile.eventgrants.split('|')[1]
                    }];
            } else {
                state.eventgrants = [];
            }
            state.sub = user.profile.sub;
        },
    },
    actions: {
        async getUser({commit, state, getters}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return getters.getUser;
            } else {
                return getters.getUser;
            }
        },
        async getUserName({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.name;
            } else {
                return state.name;
            }
        },
        async getUserSub({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.sub;
            } else {
                return state.sub;
            }
        },
        async getUserId({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.sub;
            } else {
                return state.sub;
            }
        },
        async getModuleGrants({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.modulegrants;
            } else {
                return state.modulegrants;
            }
        },
        async getSegmentGrants({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.segmentgrants;
            } else {
                return state.segmentgrants;
            }
        },
        async getFeatureGrants({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.featuregrants;
            } else {
                return state.featuregrants;
            }
        },
        async getEventGrants({commit, state}) {
            if (state.sub === null) {
                let user = await state.userManager.getUserInfo();
                commit('updateUser', user);
                return state.eventgrants;
            } else {
                return state.eventgrants;
            }
        },
        async validateResource({commit, state}, resourceId) {
            //this method returns true if the user has access to the resource
            return state.userManager.validateResource(resourceId);
        },
        async getValidResources({commit, state}, message) {
            return state.userManager.getValidResources(message);
        },
        requireAuth({commit, state}, payload) {
            state.userManager.requireAuth(payload.to, payload.from, payload.next);
        },
        login({commit, state}) {
            state.userManager.login();
        }
    },
    getters: {
        getUser(state) {
            return {
                email: state.email,
                email_verified: state.email_verified,
                modulegrants: state.modulegrants,
                role: state.role,
                segmentgrants: state.segmentgrants,
                eventgrants: state.eventgrants,
                profilePicture: state.profilePicture,
                sub: state.sub,
                name: state.name
            };
        },
        getModuleGrants(state) {
            return state.modulegrants;
        },
        getSegmentGrants(state) {
            return state.segmentgrants;
        },
        getFeatureGrants(state) {
            return state.featuregrants;
        },
        getEventGrants(state) {
            return state.eventgrants;
        },
        getAccessToken(state) {
            return state.userManager.getAccessToken();
        },
        getIdToken(state) {
            return state.userManager.getIdToken();
        },
        getUserManager(state) {
            return state.userManager;
        },
        getUserRole(state) {
            return state.role;
        },
        getHomeTenantId(state) {
            return state.homeTenantId;
        }
    }
});

export default authStore;
