import gammaStore from "@/store/gammaStore";
import PubSub from 'pubsub-js';
import authStore from "@/store/authStore";

const Disks = 0;
const Recordings = 1;
const Download = 2;
const Playback = 3;
const Stop = 4;


const Video = 0;
const Audio = 1;
const Information = 2 ;
const Analytic = 3;
const VideoArchive = 4;
const AudioVideo = 5;
const AudioVideoArchive = 6;
const AvSync = 7;
const SdCard = 8;

function SmartSuiteSdCard(device, sourceToken, dataChannel) {
    this.sourceToken = sourceToken;
    this.dataChannel = dataChannel
    this.gamma = null;
    this.device = device;
}


SmartSuiteSdCard.prototype.connect = async function (sdCardId) {
    this.gamma = await gammaStore.dispatch('getSdCardhub', this.device.getTenantId());
    PubSub.publish('sdCardConnected'+sdCardId);
};

SmartSuiteSdCard.prototype.close = async function () {
    await gammaStore.dispatch('closeSdCardhub', this.device.getTenantId());
};

SmartSuiteSdCard.prototype.newSdCardRequest = async function (requestType) {
    let username = await authStore.dispatch('getUserName');
    return {
        clientConnectionId: this.gamma.getConnectionId(),
        hubName: this.device.getHubName(),
        serialNumber: this.device.getSerialNumber(),
        dataChannel: this.device.getSerialNumber().toLowerCase() + '_sd_' + username + '_' + Date.now().toString(),
        sdCardRequestType: requestType
    };
};

SmartSuiteSdCard.prototype.getSdCardRecordings = async function () {
    let request = await this.newSdCardRequest(Recordings);
    this.gamma.sendMessage('GetRecordingDetails', request);
}

SmartSuiteSdCard.prototype.streamSdCardRequest = async function (startTime, endTime, record, callback) {
    let request =  await this.newSdCardRequest(Playback);
    let fps = record.video.framerate.split(':');
    this.device.getStreamServer([VideoArchive, AudioVideoArchive], async streamServer => {
        let tempArray = streamServer.split('/');
        request.recordingId = record.recordingid;
        request.streamServer = tempArray[0]+'//'+tempArray[2]+'/';
        request.startTime = Math.floor(startTime/1000);
        request.stopTime = Math.floor(endTime/1000);
        request.sdCard = true;
        request.fps = parseInt(fps[0]);
        request.requestId = await this.gamma.sendMessage('CacheSdCardRequest', request);
        request.isContinuous = startTime === 0;
        callback(request);
    })
}

SmartSuiteSdCard.prototype.exportSdCardRequest = async function (startTime, endTime, record, exportName, callback) {
    let moduleGrants = await authStore.dispatch('getModuleGrants');
    if (moduleGrants && moduleGrants.includes('archiveexport')) {
        let request =  await this.newSdCardRequest(Download);
        this.device.getStreamServer([VideoArchive, AudioVideoArchive], async streamServer => {
            let tempArray = streamServer.split('/');
            request.recordingId = record.recordingid;
            request.diskId = record.diskid;
            request.streamServer = tempArray[0]+'//'+tempArray[2]+'/';
            request.startTime = Math.floor(startTime/1000);
            request.stopTime = Math.floor(endTime/1000);
            request.storageFileName = exportName;
            request.requestId = await this.gamma.sendMessage('CacheSdCardRequest', request);
            this.gamma.sendMessage('DownloadRecording', request).then(() => {
                callback({
                    title: 'Success',
                    text: "Video is being compiled; you will receive a notification when it is ready",
                    style: 'success'
                });
            });
        })
    } else {
        callback({
            title: 'No Permission to Export',
            style: 'error'
        });
    }
}

SmartSuiteSdCard.prototype.sdCardDevicesListener = async function (sdCardCallback) {
    await gammaStore.dispatch('setSdCardhubListener', {
        _id: this.device.getTenantId(), event: 'sdcarddevices', callback: (event) => {
            sdCardCallback(event);
        }
    });
};

SmartSuiteSdCard.prototype.sdCardRecordingsListener = async function (sdCardCallback) {
    await gammaStore.dispatch('setSdCardhubListener', {
        _id: this.device.getTenantId(), event: 'recordings', callback: (event) => {
            sdCardCallback(event);
        }
    });
};
export default SmartSuiteSdCard