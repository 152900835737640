import {requireAuth} from '@/plugins/risckeeper-plugin'

const OrganizationsOverview = () => import ('@/views/expansion_modules/risc/organization_management/OrganizationsOverview');
const OrganizationDetails = () => import ('@/views/expansion_modules/risc/organization_management/OrganizationDetails');
//const OldOverview = () => import('@/views/expansion_modules/risc/OldOverview');
const Profiles = () => import('@/views/expansion_modules/risc/profile_management/Profiles');
const ProfilesDetails = () => import('@/views/expansion_modules/risc/profile_management/ProfilesDetails');
//const OfficerQueue = () => import('@/views/expansion_modules/risc/OfficerQueue');
//const SupervisorQueue = () => import('@/views/expansion_modules/risc/SupervisorQueue');
//const RiscCase = () => import('@/views/expansion_modules/risc/Cases');
const IntelCaseOverview = () => import('@/views/expansion_modules/risc/intel_case_management/IntelCaseOverview');
const IntelCaseDetails = () => import('@/views/expansion_modules/risc/intel_case_management/IntelCaseDetails');
const RISCFileUpload = () => import('@/views/expansion_modules/risc/FileUpload');
const Vehicles = () => import('@/views/expansion_modules/risc/Vehicles');
const Addresses = () => import('@/views/expansion_modules/risc/Addresses');
const Drugs = () => import('@/views/expansion_modules/risc/Drugs');
const Weapons = () => import('@/views/expansion_modules/risc/Weapons');
const LEReports = () => import('@/views/expansion_modules/risc/LEReports')

export default [
    {
        path: 'risc',
        redirect: '/risc/profiles',
        name: 'RISC',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'organizations/',
                name: 'Organization',
                //beforeEnter: requireAuth,
                component: OrganizationsOverview,

            },
            /*{
                path: 'old',
                name: 'OldOverview',
                beforeEnter: requireAuth,
                component: OldOverview
            },*/
            {
                path: 'organization/:id',
                name: 'Organization Details',
                //beforeEnter: requireAuth,
                component: OrganizationDetails,
                props: true,
            },
            {
                path: 'profiles/',
                name: 'Profiles',
                //beforeEnter: requireAuth,
                component: Profiles,
            },
            {
                path: 'profile/:id',
                name: 'Profile Details',
                //beforeEnter: requireAuth,
                component: ProfilesDetails,
                props: true,
            },
            /*{
                path: 'officerqueue',
                name: "OfficerQueue",
                beforeEnter: requireAuth,
                component: OfficerQueue
            },
            {
                path: 'supervisorqueue',
                name: 'Supervisor Queue',
                beforeEnter: requireAuth,
                component: SupervisorQueue
            },
            {
                path: 'case',
                name: 'case',
                beforeEnter: requireAuth,
                component: RiscCase
            },*/
            {
                path: 'intelcases',
                //beforeEnter: requireAuth,
                name: 'Intel Case Overview',
                component: IntelCaseOverview
            },
            {
                path: 'intelcase/:id',
                name: 'Intel Case Details',
                //beforeEnter: requireAuth,
                component: IntelCaseDetails,
                props: true,
            },
            {
                path: 'file-upload',
                name: 'FileUpload',
                //beforeEnter: requireAuth,
                component: RISCFileUpload,
            },
            {
                path: 'vehicles',
                name: 'Vehicles',
                //beforeEnter: requireAuth,
                component: Vehicles
            },
            {
                path: 'addresses',
                name: 'Addresses',
                //beforeEnter: requireAuth,
                component: Addresses
            },
            {
                path: 'drugs',
                name: 'Drugs',
                //beforeEnter: requireAuth,
                component: Drugs
            },
            {
                path: 'weapons',
                name: 'Weapons',
                //beforeEnter: requireAuth,
                component: Weapons
            },
            {
                path: 'lereports',
                name: 'LEReports',
                //beforeEnter: requireAuth,
                component: LEReports
            }
        ],
    },
]