import gammaStore from "@/store/gammaStore";

function SmartSuiteEvent(device, sourceToken, dataChannel) {
    this.sourceToken = sourceToken;
    this.dataChannel = dataChannel;
    this.gamma = null;
    this.device = device;
}

SmartSuiteEvent.prototype.connect = async function () {
    this.gamma = await gammaStore.dispatch('getMqtthub', this.device.getTenantId());
};

SmartSuiteEvent.prototype.close = async function () {
    await gammaStore.dispatch('closeMqtthub', this.device.getTenantId());
};

SmartSuiteEvent.prototype.eventListener = async function (callback) {
    await this.checkConnection();
    await gammaStore.dispatch('setMqtthubListener', {
        _id: this.device.getTenantId(), event: 'mqttevents', callback: (event) => {
            callback(event);
        }
    });
}

SmartSuiteEvent.prototype.getDeviceEventsByType = async function (eventType, startTime, endTime, callback) {
    let result = [];
    this.gamma.streamMessage('GetDeviceEvents', this.device.getDeviceId(), eventType,  endTime === null ? Date.now() : new Date(endTime).valueOf(), startTime === null ? 0 : new Date(startTime).valueOf()).subscribe({
        next: (item) => {
            result.push(item);
        },
        complete: () => {
            result.sort((a, b) => {
                return (a.startTime > b.startTime ? 1 : -1);
            });
            callback(result);
        },
        error: (err) => {
            //console.warn(err)
            callback([]);
        }
    });
}

SmartSuiteEvent.prototype.getMotionEvents = async function (callback) {
    let result = [];
    this.gamma.streamMessage('GetMotionRecEvents', this.device.getDeviceId(), this.sourceToken, Date.now(), 0).subscribe({
        next: (item) => {
            result.push(item);
        },
        complete: () => {
            /*result.sort((a, b) => {
                return (parseInt(a) > parseInt(b) ? 1 : -1);
            });*/
            callback(result);
        },
        error: (err) => {
            console.error(err);
            callback(undefined);
        }
    });
}

SmartSuiteEvent.prototype.getAllMotionEvents = async function () {
    return await this.gamma.sendMessage('GetMotionRecordings');
}

SmartSuiteEvent.prototype.initializeDevice = async function (serialNumber) {
    return await this.gamma.sendMessage('InitializeDevice', serialNumber);
}

SmartSuiteEvent.prototype.checkConnection = async function () {
    if (!this.gamma.isConnected()) {
        await this.connect();
    }
}

/*SmartSuiteEvent.prototype.getEventServer = async function (segmentId) {
    let eventGrants = await authStore.dispatch('getEventGrants');
    if (eventGrants.length > 0) {
        return eventGrants.find(eventGrant => {
            //looks like 'Events' is tagged on by default inside SmartSuite as well
           return eventGrant._id === segmentId + 'Events';
        });
    }
}*/

export default SmartSuiteEvent;