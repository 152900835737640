import Vue from 'vue';
import Vuex from 'vuex';
import authStore from "@/store/authStore";

Vue.use(Vuex);

let navbarStore = new Vuex.Store({
    state: {
        navbar: null,
        navInfo: null,
        navbars: require('@/shared/navbars').default
    },
    mutations: {
        updateNavbar(state, navbar) {
            state.navbar = navbar;
        },
        updateNavInfo(state, navInfo) {
            state.navInfo = navInfo;
        }
    },
    actions: {
        async getNavbar({commit, state, getters}) {
            if (state.navbar === null) {
                let navbar = await authStore.dispatch('getModuleGrants');
                commit('updateNavbar', navbar);
                return state.navbar;
            } else {
                return state.navbar;
            }
        },
        async getNavInfo({commit, state}) {
            if (state.navInfo === null) {
                let navIDs = (await navbarStore.dispatch('getNavbar'));
                navIDs = navIDs.map(nav => {
                    return state.navbars.filter(_ => _._id === nav && _.no_tab === false);
                }).filter(nav => { return nav.length > 0; }).flat();
                state.navbars.forEach(_ => {
                    if (_.global === true && _.no_tab === false) {
                        if (navIDs.findIndex(a => a._id === _._id) < 0) {
                            navIDs.push(_);
                        }
                    }
                });
                navIDs = navIDs.filter((navId, index) => {
                    return navIDs.findIndex(navId2 => { return navId2.url === navId.url}) === index;
                });
                commit('updateNavInfo', navIDs);
                return state.navInfo;
            } else {
                return state.navInfo;
            }
        },
        async getNav({state}, url) {
            return state.navbars.find(navbar => navbar.url === url);
        },
        async getNavClaim({commit, state}, url) {
            let navbar = state.navbars.find(navbar => navbar.url === url);
            return navbar === undefined ? navbar : navbar._id;
        }
    },
    getters: {
        getNavbar(state) {
            return state.navbar;
        },
        getNavInfo(state) {
            return state.navInfo;
        }
    }
});

export default navbarStore;