import axios from 'axios'
import decode from 'jwt-decode';

function ISS() {
    return axios.create({
        baseURL: process.env.VUE_APP_ISSENGINE,
        //withCredentials: false,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + localStorage.mtopToken
        }
    })
}

export default {
    authVerify(token) {
        return new ISS().post('/auth/verify',  {}, {headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + token
            }});
    },
    login(username, password) {
        return new ISS().post('/auth/login', {username: username, password: password}, {headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT'
            }})
    },
    twoFactor(username, password, twofactor) {
        return new ISS().post('/auth/verify/twofactor', {username: username, password: password, twoFactor: twofactor}, {headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT'
            }})
    },
    getUsersInGroup() {
        return new ISS().get('/auth/accounts')
    },
    deleteUser(id) {
        return new ISS().delete('/auth/account/' + id);
    },
    createUser(user) {
        return new ISS().post('auth/register', {
            username: user.username,
            password: user.password,
            email: user.email,
            type: user.type,
            groups: user.groups,
            firstname: user.firstname,
            lastname: user.lastname,
            phoneNumber: user.phoneNumber,
            countryCode: user.countryCode,
            customClaims: user.customClaims
        });
    },
    editUser(user) {
        return new ISS().put('/auth/account/' + user._id, {
            username: user.username,
            password: user.password,
            email: user.email,
            type: user.type,
            groups: user.groups,
            firstname: user.firstname,
            lastname: user.lastname,
            phoneNumber: user.phoneNumber,
            countryCode: user.countryCode,
            customClaims: user.customClaims,
            customFields: user.customFields
        });
    },

    //Permissions
    listPermissions() {
        return new ISS().get('/permission/list')
    },
    addPermissions(usernames, groups, permissions) {
        return new ISS().put('/permission/many/add', {usernames: usernames, groups: groups, permissions: permissions})
    },
    removePermissions(usernames, groups, permissions) {
        return new ISS().put('/permission/many/remove', {usernames: usernames, groups: groups, permissions: permissions})
    },

    //Claims
    getClaims() {
        return new ISS().get('/claims');
    },
    getUserClaims() {
        return new ISS().get('/claims/user');
    },
    postClaims(claim) {
        return new ISS().post('/claims/',claim);
    },
    putUpdateClaims(data) {
        return new ISS().put('/claims/' + data._id, data);
    },
    deleteClaim(id) {
        return new ISS().delete('/claims/' + id);
    },

    //Groups
    getAllGroups() {
        return new ISS().get('/groups/list')
    },
    updateGroups(group, constellations) {
        return new ISS().put('/groups/' + group._id, {
            groupname: group.groupname,
            constellations: constellations,
            customClaims: group.customClaims,
            customFields: group.customFields
        });
    },
    deleteGroups(groupId) {
        return new ISS().delete('/groups/' + groupId)
    },
    createGroups(group, constellations) {
        return new ISS().post('/groups', {
            groupname: group.groupname,
            constellations: constellations,
            customClaims: group.customClaims,
            customFields: group.customFields
        });
    },
    updateActiveGroup(active, groupId) {
        return new ISS().put('/groups/' + groupId, {active: active})
    },

    //Constellation
    getConstellations() {
        return new ISS().get('/constellations/list');
    },
    editConstellation(constellation) {
        return new ISS().put('/constellations/' + constellation._id, {
            constellationname: constellation.constellationname,
            url: constellation.url,
            port: constellation.port,
            location: constellation.location
        })
    },
    deleteConstellation(id) {
        return new ISS().delete('/constellations/' + id)
    },
    createConstellation(constellation) {
        return new ISS().post('/constellations', {
            constellationname: constellation.constellationname,
            url: constellation.url,
            port: constellation.port,
            location: constellation.location
        })
    },
    getConstellation(id) {
        return new ISS().get('/constellations/' + id)
    },

    // Services
    readServices() {
        return new ISS().get('/services');
    },
    createService(service) {
        return new ISS().post('/services', service);
    },
    updateService(service) {
        return new ISS().put('/services/' + service._id, {
            _id: service._id,
            customClaims: service.customClaims,
            serviceName: service.serviceName,
            type: service.type
        });
    },
    deleteService(id) {
        return new ISS().delete('/services/' + id);
    },

    // Pass Reset
    startPassReset(username, phonenumber) {
        return new ISS().post('/auth/start/passwordreset', {username: username, phoneNumber: phonenumber}, {headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT'
            }});
    },
    passReset(token, password) {
        return new ISS().post('/auth/passwordreset', {password: password}, {headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + token
            }});
    },
    ISS
}

if(localStorage.getItem("started") === null) {
    localStorage.setItem("started", "false");
}