import gammaStore from "@/store/gammaStore";
import PubSub from 'pubsub-js';

const Archive = 0;
const LiveStream = 1;
const JobCancellation = 2;

const Object = 0;
const LPR = 1;
const Face = 2;

function SmartSuiteAnalytics(device, sourceToken) {
    this.sourceToken = sourceToken;
    this.gamma = null;
    this.device = device;
}

SmartSuiteAnalytics.prototype.connect = async function (analyticsId) {
    this.gamma = await gammaStore.dispatch('getAnalyticshub', this.device.getTenantId());
    PubSub.publish('analyticsConnected'+analyticsId);
};

SmartSuiteAnalytics.prototype.close = async function () {
    await gammaStore.dispatch('closeAnalyticshub', this.device.getTenantId());
};

SmartSuiteAnalytics.prototype.newAnalyticsRequest = async function (requestType, requestModule) {
    return {
        AnalyticsRequestType: requestType,
        AnalyticsRequestModule: requestModule,
        Origin: 'Satellite',
        ScanQueue: null,    //segments
        TargetClass: null,  //what you are searching for
        Audience: "analyticsservice",
        StorageServer: this.device.getStorageServer(),
        ClientConnectionId: this.gamma.getConnectionId(),
    };
};

SmartSuiteAnalytics.prototype.findAnalyticsSegments = async function (requestedSegments, targetClass) {
    let request = await this.newAnalyticsRequest(Archive, Object);
    request.ScanQueue = requestedSegments.map(segment => ({
        DeviceStoreId: segment.storeName,
        DayCode: segment.dayCode.toString(),
        HourCode: segment.hourCode.toString(),
        RequestedSegment: segment.segment.toString()
    }));
    request.TargetClass = targetClass;
    this.gamma.sendMessage('SendAnalyticsRequest', request);
};

SmartSuiteAnalytics.prototype.stopAnalyticsJob = async function (jobId) {
    let request = await this.newAnalyticsRequest(JobCancellation, Object);
    request.jobId = jobId;
    this.gamma.sendMessage('SendAnalyticsRequest', request).catch(e => {});
}

SmartSuiteAnalytics.prototype.analyticsResponseListener = async function (analyticsCallback) {
    await gammaStore.dispatch('setAnalyticshubListener', {
        _id: this.device.getTenantId(), event: 'analyticsresponse', callback: (event) => {
            analyticsCallback(event);
        }
    });
};

export default SmartSuiteAnalytics;