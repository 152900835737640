/* FIDS */
const FidsCreate = () => import('@/views/expansion_modules/fids/fids_create');
const FidsManage = () => import('@/views/expansion_modules/fids/fids_manage');
const FidsUpdate = () => import('@/views/expansion_modules/fids/fids_update');
const FidsAirlines = () => import('@/views/expansion_modules/fids/FidsAirlines');

/* LPR */
const Lpr = () => import('@/views/expansion_modules/LPR/LPR.vue');

/* Digital Evidence */
const UserView = () => import ('@/views/expansion_modules/digital_evidence/UserView');
const SupervisorView = () => import ('@/views/expansion_modules/digital_evidence/SupervisorView');
const EvidenceView = () => import ('@/views/expansion_modules/digital_evidence/EvidenceView');
const UploadPage = () => import('@/views/expansion_modules/digital_evidence/UploadPage');
const Audit = () => import('@/views/expansion_modules/digital_evidence/Audit');
const WeeklyReport = () => import('@/views/expansion_modules/digital_evidence/WeeklyReport');

/* DIS */
const DISOverview = () => import('@/views/expansion_modules/dis/dis_overview');
const DISImageUpload = () => import('@/views/expansion_modules/dis/image_upload');

export default [
    {
        path: '/fids/update/:id',
        name: 'Fids Update',
        component: FidsUpdate,
        props: true,
    },
    {
        path: '/fids/airlines',
        name: 'Fids Airlines',
        component: FidsAirlines
    },
    {
        path: '/fids/create',
        name: 'FIDS Create',
        component: FidsCreate,
    },
    {
        path: '/fids/manage',
        name: 'FIDS Manage',
        component: FidsManage
    },
    {
        path: '/lpr',
        name: 'LPR',
        component: Lpr,
    },
    {
        path: '/digitalevidence',
        redirect: '/digitalevidence/user',
        name: 'digitalevidence',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'user',
                name: 'User',
                component: UserView
            },
            {
                path: 'supervisor',
                name: 'Supervisor',
                component: SupervisorView
            },
            {
                path: 'evidence',
                name: 'Evidence',
                component: EvidenceView
            },
            {
                path: 'upload',
                name: 'Upload',
                component: UploadPage
            },
            {
                path: 'audit',
                name: 'Audit',
                component: Audit
            },
            {
                path: 'weekly-report',
                name: 'Weekly Report',
                component: WeeklyReport
            }
        ]
    },
    {
        path: 'dis',
        redirect: '/dis/overview',
        name: 'Dynamic Information Screen',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'overview',
                name: 'DIS Overview',
                component: DISOverview
            },
        ]
    },
    {
        path: '/images',
        name: 'ImageUpload',
        component: DISImageUpload,
        props: (route) => ({constellation: route.query.constellation, deviceId: route.query.deviceId})
    },
];