import Vue from 'vue';
import Vuex from 'vuex';
import Gamma from '@/services/gamma';
import tenantStore from "./tenantStore";
import deviceStore from "./deviceStore";

Vue.use(Vuex);

let gammaStore = new Vuex.Store({

    state: {
        devicehubs: {},
        mediahubs: {},
        mediahub: null,
        archivehubs: {},
        ptzhubs: {},
        livestreamhubs: {},
        analyticshubs: {},
        sdcardhubs: {},
        anprhubs: {},
        mqtthubs: {},
        gpshubs: {}
    },

    mutations: {
        updateDevicehub(state, gamma) {
            state.devicehubs[gamma._id] = gamma.devicehub;
        },
        updateMediahub(state, gamma) {
            state.mediahub = gamma.mediahub;
        },
        updateArchivehub(state, gamma) {
            state.archivehubs[gamma._id] = gamma.archivehub;
        },
        updatePTZhub(state, gamma) {
            state.ptzhubs[gamma._id] = gamma.ptzhub;
        },
        updateLiveStreamhub(state, gamma) {
            state.livestreamhubs[gamma._id] = gamma.livestreamhub;
        },
        updateAnalyticshub(state, gamma) {
            state.analyticshubs[gamma._id] = gamma.analyticshub;
        },
        updateSdCardhub(state, gamma) {
            state.sdcardhubs[gamma._id] = gamma.sdcardhub;
        },
        updateANPRhub(state, gamma) {
            state.anprhubs[gamma._id] = gamma.anprhub;
        },
        updateMqtthub(state, gamma) {
            state.mqtthubs[gamma._id] = gamma.mqtthub;
        },
        updateGPShub(state, gamma) {
            state.gpshubs[gamma._id] = gamma.gpshub;
        },
    },

    actions: {
        async refreshHubs({getters}) {
            for (const hub in getters.getAllDevicehubs) {
                if (getters.getDevicehub(hub) !== undefined) {
                    getters.getDevicehub(hub).refresh();
                }
            }
            //There should only ever be one mediahub opened
            if (getters.getMediahub !== null) {
                let mediahub = getters.getMediahub
                let tenants = tenantStore.getters.getTenants;
                mediahub.refresh();
                // set listener for each tenant
                for (const tenant of tenants) {
                    await deviceStore.dispatch('setMediahubListener', tenant._id);
                }
                // set each preexisting listener
                await deviceStore.dispatch('resetEdgeListeners');

            }
            for (const hub in getters.getAllArchivehubs) {
                if (getters.getArchivehub(hub) !== undefined) {
                    getters.getArchivehub(hub).refresh();
                }
            }
            for (const hub in getters.getAllPTZhubs) {
                if (getters.getPTZhub(hub) !== undefined) {
                    getters.getPTZhub(hub).refresh();
                }
            }
            for (const hub in getters.getAllLiveStreamhubs) {
                if (getters.getLiveStreamhub(hub) !== undefined) {
                    getters.getLiveStreamhub(hub).refresh();
                }
            }
            for (const hub in getters.getAllAnalyticshubs) {
                if (getters.getAnalyticshub(hub) !== undefined) {
                    getters.getAnalyticshub(hub).refresh();
                }
            }
            for (const hub in getters.getAllSdCardhubs) {
                if (getters.getSdCardhub(hub) !== undefined) {
                    getters.getSdCardhub(hub).refresh();
                }
            }
            for (const hub in getters.getAllANPRhubs) {
                if (getters.getANPRhub(hub) !== undefined) {
                    getters.getANPRhub(hub).refresh();
                }
            }
            for (const hub in getters.getAllMqtthubs) {
                if (getters.getMqtthub(hub) !== undefined) {
                    getters.getMqtthub(hub).refresh();
                }
            }
        },
        closeActiveHubs({getters}) {
            for (const hub in getters.getAllDevicehubs) {
                if (getters.getDevicehub(hub) !== undefined) {
                    getters.getDevicehub(hub).close();
                }
            }
            //There should only ever be one mediahub opened
            if (getters.getMediahub !== null) {
                getters.getMediahub.close();
            }
            for (const hub in getters.getAllArchivehubs) {
                if (getters.getArchivehub(hub) !== undefined) {
                    getters.getArchivehub(hub).close();
                }
            }
            for (const hub in getters.getAllPTZhubs) {
                if (getters.getPTZhub(hub) !== undefined) {
                    getters.getPTZhub(hub).close();
                }
            }
            for (const hub in getters.getAllLiveStreamhubs) {
                if (getters.getLiveStreamhub(hub) !== undefined) {
                    getters.getLiveStreamhub(hub).close();
                }
            }
            for (const hub in getters.getAllAnalyticshubs) {
                if (getters.getAnalyticshub(hub) !== undefined) {
                    getters.getAnalyticshub(hub).close();
                }
            }
            for (const hub in getters.getAllSdCardhubs) {
                if (getters.getSdCardhub(hub) !== undefined) {
                    getters.getSdCardhub(hub).close();
                }
            }
            for (const hub in getters.getAllANPRhubs) {
                if (getters.getANPRhub(hub) !== undefined) {
                    getters.getANPRhub(hub).close();
                }
            }
            for (const hub in getters.getAllMqtthubs) {
                if (getters.getMqtthub(hub) !== undefined) {
                    getters.getMqtthub(hub).close();
                }
            }
        },
        closeActiveListeners({getters}) {
            for (const hub in getters.getAllDevicehubs) {
                if (getters.getDevicehub(hub) !== undefined) {
                    getters.getDevicehub(hub).stopListeners();
                }
            }
            //There should only ever be one mediahub opened
            if (getters.getMediahub !== null) {
                getters.getMediahub.stopListeners();
            }
            for (const hub in getters.getAllArchivehubs) {
                if (getters.getArchivehub(hub) !== undefined) {
                    getters.getArchivehub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllPTZhubs) {
                if (getters.getPTZhub(hub) !== undefined) {
                    getters.getPTZhub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllLiveStreamhubs) {
                if (getters.getLiveStreamhub(hub) !== undefined) {
                    getters.getLiveStreamhub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllAnalyticshubs) {
                if (getters.getAnalyticshub(hub) !== undefined) {
                    getters.getAnalyticshub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllSdCardhubs) {
                if (getters.getSdCardhub(hub) !== undefined) {
                    getters.getSdCardhub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllANPRhubs) {
                if (getters.getANPRhub(hub) !== undefined) {
                    getters.getANPRhub(hub).stopListeners();
                }
            }
            for (const hub in getters.getAllMqtthubs) {
                if (getters.getMqtthub(hub) !== undefined) {
                    getters.getMqtthub(hub).stopListeners();
                }
            }
        },
        checkForOpenMediahub ({state, getters}) {
            if (state.mediahubs !== undefined) {
                let gamma = getters.getMediahub;
                if (gamma !== null) {
                    return gamma.isConnected() || gamma.isConnecting();
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        async getDevicehub({commit, state}, tenantId) {
            if (state.devicehubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateDevicehub', {_id: tenantId, devicehub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/devicehub');
                return state.devicehubs[tenantId];
            } else {
                if(!state.devicehubs[tenantId].isConnected()) {
                    await state.devicehubs[tenantId].refresh();
                }
                return state.devicehubs[tenantId];
            }
        },
        async setDevicehubListener({commit, getters, dispatch}, payload) {
            let gamma = getters.getDevicehub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateDevicehub', {_id: payload._id, devicehub: gamma});
        },
        closeDevicehub({commit, getters}, tenantId) {
            let gamma = getters.getDevicehub(tenantId);
            gamma.close();
            commit('updateDevicehub', {_id: tenantId, devicehub: undefined});
        },
        checkMediahub ({state}) {
            return state.mediahub !== undefined && (state.mediahub.isConnected() === true || state.mediahub.isConnecting() === true);
        },
        async getMediahub({commit, state}) {
            if (state.mediahub === null) {
                let gamma = new Gamma();
                commit('updateMediahub', {mediahub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/mediahub');
                return state.mediahub;
            } else {
                if(!state.mediahub.isConnected()) {
                    await state.mediahub.refresh();
                }
                return state.mediahub;
            }
        },
        setMediahubListener({commit, getters}, payload) {
            let gamma = getters.getMediahub;
            gamma.setListener(payload.event, payload.callback);
            commit('updateMediahub', {mediahub: gamma});
        },
        closeMediahub({commit, getters}) {
            let gamma = getters.getMediahub;
            gamma.close();
            commit('updateMediahub', {mediahub: undefined});
        },
        async getArchivehub({commit, state}, tenantId) {
            if (state.archivehubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateArchivehub', {_id: tenantId, archivehub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/archivehub');
                return state.archivehubs[tenantId];
            } else {
                if(!state.archivehubs[tenantId].isConnected() && !state.archivehubs[tenantId].isConnecting()) {
                    try {
                        await state.archivehubs[tenantId].refresh();
                    } catch (e) {}
                }
                return state.archivehubs[tenantId];
            }
        },
        setArchivehubListener({commit, getters}, payload) {
            let gamma = getters.getArchivehub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateArchivehub', {_id: payload._id, archivehub: gamma});
        },
        async closeArchivehub({commit, getters}, tenantId) {
            let gamma = getters.getArchivehub(tenantId);
            await gamma.close();
            commit('updateArchivehub', {_id: tenantId, archivehub: undefined});
        },
        async getSdCardhub({commit, state}, tenantId) {
            if (state.sdcardhubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateSdCardhub', {_id: tenantId, sdcardhub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/sdcardhub');
                return state.sdcardhubs[tenantId];
            } else {
                if(!state.sdcardhubs[tenantId].isConnected()) {
                    await state.sdcardhubs[tenantId].refresh();
                }
                return state.sdcardhubs[tenantId];
            }
        },
        setSdCardhubListener({commit, getters}, payload) {
            let gamma = getters.getSdCardhub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateSdCardhub', {_id: payload._id, sdcardhub: gamma});
        },
        closeSdCardhub({commit, getters}, tenantId) {
            let gamma = getters.getSdCardhub(tenantId);
            gamma.close();
            commit('updateSdCardhub', {_id: tenantId, sdcardhub: undefined});
        },
        async getAnalyticshub({commit, state}, tenantId) {
            if (state.analyticshubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateAnalyticshub', {_id: tenantId, analyticshub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/analyticshub');
                return state.analyticshubs[tenantId];
            } else {
                if(!state.analyticshubs[tenantId].isConnected()) {
                    await state.analyticshubs[tenantId].refresh();
                }
                return state.analyticshubs[tenantId];
            }
        },
        setAnalyticshubListener({commit, getters}, payload) {
            let gamma = getters.getAnalyticshub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateAnalyticshub', {_id: payload._id, analyticshub: gamma});
        },
        closeAnalyticshub({commit, getters}, tenantId) {
            let gamma = getters.getAnalyticshub(tenantId);
            gamma.close();
            commit('updateAnalyticshub', {_id: tenantId, analyticshub: undefined});
        },
        async getANPRhub({commit, state}, tenantId) {
            if (state.anprhubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateANPRhub', {_id: tenantId, anprhub: gamma});
                await gamma.connect(config.VUE_APP_EVENTSERVER, '/anprhub');
                return state.anprhubs[tenantId];
            } else {
                if(!state.anprhubs[tenantId].isConnected()) {
                    await state.anprhubs[tenantId].refresh();
                }
                return state.anprhubs[tenantId];
            }
        },
        setANPRhubListener({commit, getters}, payload) {
            let gamma = getters.getANPRhub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateANPRhub', {_id: payload._id, anprhub: gamma});
        },
        closeANPRhub({commit, getters}, tenantId) {
            let gamma = getters.getANPRhub(tenantId);
            gamma.close();
            commit('updateANPRhub', {_id: tenantId, anprhub: undefined});
        },
        async getPTZhub({commit, state}, tenantId) {
            if (state.ptzhubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updatePTZhub', {_id: tenantId, ptzhub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/ptzhub');
                return state.ptzhubs[tenantId];
            } else {
                if(!state.ptzhubs[tenantId].isConnected()) {
                    await state.ptzhubs[tenantId].refresh();
                }
                return state.ptzhubs[tenantId];
            }
        },
        setPTZhubListener({commit, getters}, payload) {
            let gamma = getters.getPTZhub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updatePTZhub', {_id: payload._id, ptzhub: gamma});
        },
        closePTZhub({commit, getters}, tenantId) {
            let gamma = getters.getPTZhub(tenantId);
            gamma.close();
            commit('updatePTZhub', {_id: tenantId, ptzhub: undefined});
        },
        async getLiveStreamhub({commit, state}, payload) {
            if (state.livestreamhubs[payload.tenantId] === undefined || payload.newConnection === true) {
                let gamma = new Gamma();
                commit('updateLiveStreamhub', {_id: payload.tenantId, livestreamhub: gamma});
                await gamma.connect(config.VUE_APP_MESSAGESERVER, '/livehub');
                return state.livestreamhubs[payload.tenantId];
            } else {
                if(!state.livestreamhubs[payload.tenantId].isConnected() && !state.livestreamhubs[payload.tenantId].isConnecting()) {
                    await state.livestreamhubs[payload.tenantId].refresh();
                }
                return state.livestreamhubs[payload.tenantId];
            }
        },
        setLiveStreamhubListener({commit, getters}, payload) {
            let gamma = getters.getLiveStreamhub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateLiveStreamhub', {_id: payload._id, livestreamhub: gamma});
        },
        closeLiveStreamhub({commit, getters}, tenantId) {
            let gamma = getters.getLiveStreamhub(tenantId);
            gamma.close();
            commit('updateLiveStreamhub', {_id: tenantId, livestreamhub: undefined});
        },
        closeLiveStreamhubListeners({commit, getters}, tenantId) {
            let gamma = getters.getLiveStreamhub(tenantId);
            gamma.stopListeners();
            commit('updateLiveStreamhub', {_id: tenantId, livestreamhub: gamma});
        },
        async getMqtthub({commit, state}, tenantId) {
            if (state.mqtthubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateMqtthub', {_id: tenantId, mqtthub: gamma});
                await gamma.connect(config.VUE_APP_EVENTSERVER, '/mqtthub');
                return state.mqtthubs[tenantId];
            } else {
                if(!state.mqtthubs[tenantId].isConnected()) {
                    await state.mqtthubs[tenantId].refresh();
                }
                return state.mqtthubs[tenantId];
            }
        },
        setMqtthubListener({commit, getters}, payload) {
            let gamma = getters.getMqtthub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateMqtthub', {_id: payload._id, mqtthub: gamma});
        },
        closeMqtthub({commit, getters}, tenantId) {
            let gamma = getters.getMqtthub(tenantId);
            gamma.close();
            commit('updateMqtthub', {_id: tenantId, mqtthub: undefined});
        },
        async getGPShub({commit, state}, tenantId) {
            if (state.gpshubs[tenantId] === undefined) {
                let gamma = new Gamma();
                commit('updateGPShub', {_id: tenantId, gpshub: gamma});
                await gamma.connect(config.VUE_APP_EVENTSERVER, '/gpshub');
                return state.gpshubs[tenantId];
            } else {
                if(!state.gpshubs[tenantId].isConnected()) {
                    await state.gpshubs[tenantId].refresh();
                }
                return state.gpshubs[tenantId];
            }
        },
        setGPShubListener({commit, getters}, payload) {
            let gamma = getters.getGPShub(payload._id);
            gamma.setListener(payload.event, payload.callback);
            commit('updateGPShub', {_id: payload._id, gpshub: gamma});
        },
        closeGPShub({commit, getters}, tenantId) {
            let gamma = getters.getGPShub(tenantId);
            gamma.close();
            commit('updateGPShub', {_id: tenantId, gpshub: undefined});
        }
    },

    getters: {
        getAllHubs(state) {
            return state;
        },
        getAllDevicehubs(state) {
            return state.devicehubs;
        },
        getDevicehub(state) {
            return (tenantId) => {
                return state.devicehubs[tenantId];
            }
        },
        getAllMediahubs(state) {
            return state.mediahubs;
        },
        /*getMediahub(state) {
            return (tenantId) => {
                return state.mediahubs[tenantId];
            }
        },*/
        getMediahub(state) {
            return state.mediahub;
        },
        getAllArchivehubs(state) {
            return state.archivehubs;
        },
        getArchivehub(state) {
            return (tenantId) => {
                return state.archivehubs[tenantId];
            }
        },
        getAllAnalyticshubs(state) {
            return state.analyticshubs;
        },
        getAnalyticshub(state) {
            return (tenantId) => {
                return state.analyticshubs[tenantId];
            }
        },
        getAllSdCardhubs(state) {
            return state.sdcardhubs;
        },
        getSdCardhub(state) {
            return (tenantId) => {
                return state.sdcardhubs[tenantId];
            }
        },
        getAllANPRhubs(state) {
            return state.anprhubs;
        },
        getANPRhub(state) {
            return (tenantId) => {
                return state.anprhubs[tenantId];
            }
        },
        getAllPTZhubs(state) {
            return state.ptzhubs;
        },
        getPTZhub(state) {
            return (tenantId) => {
                return state.ptzhubs[tenantId];
            }
        },
        getAllLiveStreamhubs(state) {
            return state.livestreamhubs;
        },
        getLiveStreamhub(state) {
            return (tenantId) => {
                return state.livestreamhubs[tenantId];
            }
        },
        getAllMqtthubs(state) {
            return state.mqtthubs;
        },
        getMqtthub(state) {
            return (tenantId) => {
                return state.mqtthubs[tenantId];
            }
        },
        getAllGPShubs(state) {
            return state.gpshubs;
        },
        getGPShub(state) {
            return (tenantId) => {
                return state.gpshubs[tenantId];
            }
        }
    }
});

export default gammaStore;