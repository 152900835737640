import Vue from 'vue';
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";
import MToast from "@/components/m-toast.vue";

Vue.use(Toast, {});

export default {
    install(Vue) {
        Vue.prototype.$mToast = function (data) {
            if (typeof data === 'string') {
                data = {title: null, text: data, style: null}
            }
            let {title, text, style, timeout} = data;
            let params = [
                {
                    component: MToast,
                    props: {
                        title: title,
                        text: text
                    }
                },
                {position: 'bottom-right'}
            ]
            if (timeout !== undefined) {
                params[1].timeout = timeout
            }
            switch(style) {
                case 'success':
                    this.$toast.success(...params);
                    break;
                case 'info':
                    this.$toast.info(...params);
                    break;
                case 'warning':
                    this.$toast.warning(...params);
                    break;
                case 'danger':
                case 'error':
                    this.$toast.error(...params);
                    break;
                case 'primary':
                case 'default':
                default:
                    this.$toast(...params);
                    break;
            }
        }
    },
};