export default [
    {
        _id : "vision",
        name : "Video Wall",
        url : "/video-wall",
        icon : "fa-solid fa-video",
        no_tab: false,
        children : [

        ]
    },
    {
        _id : "devices",
        name : "Devices",
        url : "/devices",
        icon : "fa-solid fa-server",
        no_tab: true,
        global: true
    },
    {
        _id : "dashboard",
        name : "Dashboard",
        url : "/dashboard",
        icon : "fa-solid fa-home",
        no_tab: true,
        global : true
    },
    {
        _id : "login",
        name : "Login",
        url : "/pages/login",
        icon : "fa-solid fa-key",
        no_tab: true,
        global : true
    },
    {
        _id : "reportcases",
        name : "Cases",
        url : "/cases",
        icon : "fa-solid fa-briefcase",
        no_tab: false,
        global : false
    },
    {
        _id : "reportcase",
        name : "Case",
        url : "/case/:caseId",
        icon : "fa-solid",
        no_tab: true,
        global : false
    },
    {
        _id : "reportcase",
        name : "Case",
        url : "/case/:caseId/:reportId",
        icon : "fa-solid",
        no_tab: true,
        global : false
    },
    {
        _id : "reportsupervisor",
        name : "Supervisor View",
        url : "/cases/supervisor",
        icon : "fa-solid fa-user-secret",
        no_tab: false,
        global : false
    },
    {
        _id : "reporttemplates",
        name : "Templates",
        url : "/templates",
        icon : "fa-regular fa-file-lines",
        no_tab: false,
        global : false
    },
    {
        _id : "reportrecords",
        name : "Reports",
        url : "/reports",
        icon : "fa-regular fa-comment-dots",
        no_tab: false,
        global : false
    },
    {
        _id : "reporttemplates",
        name : "Template",
        url : "/template/:id",
        icon : "fa-solid",
        no_tab: true,
        global : false
    },
    {
        _id : "lpr",
        name : "LPR",
        url : "/lpr",
        icon : "fa-solid fa-car",
        global : false,
        no_tab: false
    },
    {
        _id : "intel",
        name : "Intel Profiles",
        url : "/risc/profiles",
        icon : "fa-solid fa-user-group",
        no_tab: false,
        global : false
    },
    {
        _id : "intel",
        name : "Intel Organizations",
        url : "/risc/organizations",
        icon : "fa-solid fa-sitemap",
        no_tab: false,
        global : false
    },
    {
        _id : "intel",
        name : "Intel Organization",
        url : "/risc/organization/:id",
        icon : "fa-solid fa-video",
        global : false,
        no_tab: true
    },
    {
        _id : "intel",
        name : "Intel Profile",
        url : "/risc/profile/:id",
        icon : "",
        global : false,
        no_tab: true
    },
    {
        _id: 'intel',
        name: 'IntelCases',
        url: '/risc/intelcases',
        icon: 'fa-solid fa-briefcase',
        no_tab: true,
        global: true,
    },
    {
        _id : "archivepage",
        name : "Archives",
        url : "/archives",
        icon : "fa-solid fa-boxes-stacked",
        global : false,
        no_tab: false
    },
    {
        _id: 'icons',
        name: 'Icons',
        url: '/icons/font-awesome',
        icon: 'fa-solid fa-icons',
        no_tab: false,
        global: false
    },
    {
        _id: 'streamSharing',
        name: 'Stream Sharing',
        url: '/streamsharing',
        icon: 'fa-solid fa-share-from-square',
        no_tab: true,
        global: true
    },
    {
        _id : "map_view",
        name : "Map View",
        url : "/map-view",
        icon : "fa-solid fa-map-location-dot",
        no_tab: true,
        global: true
    },
    {
        _id: 'Exports',
        name: 'Exports',
        url: '/exports',
        icon: 'fa-solid fa-cloud-arrow-down',
        no_tab: true,
        global: true,
    }

]

/*
export default [
    {
        _id : "5f77c442-c9e3-4589-9d4b-45f5bbea248b",
        name : "LPR",
        url : "/lpr",
        icon : "fa fa-drivers-license",
        children : [

        ]
    },
    {
        _id : "41573c9f-c5c1-4ab5-9eea-dfeabdab9963",
        name : "Groups",
        url : "/groups",
        icon : "icon-people"
    },
    {
        _id : "0def8dbf-2ba9-45f6-9f5b-833b1187c10a",
        name : "Devices",
        url : "/devices",
        icon : "icon-camera"
    },
    {
        _id : "1f32832a-b073-4dd9-b6d3-7c581d496876",
        name : "Nav Tabs",
        url : "/admin/nav-tabs",
        icon : "icon-layers",
        children : [

        ]
    },
    {
        _id : "6708479e-a45f-49bf-be2d-f49aa6ea5906",
        name : "Edit Constellations",
        url : "/admin/constellations",
        icon : "icon-wrench"
    },
    {
        _id : "55e337a4-1511-4301-bbfe-06f6a9a235b1",
        name : "Users",
        url : "/admin/users",
        icon : "icon-user",
        children : [

        ]
    },
    {
        _id : "6a098962-3d08-4d10-bb8d-941bc6f267f2",
        name : "Satellite Permissions",
        url : "/admin/permissions",
        icon : "icon-shield"
    },
    {
        _id : "bb85c521-49c4-4148-ad23-40932dfa2b6a",
        name : "Kepler",
        url : "/admin/kepler",
        icon : "icon-disc"
    },
    {
        _id : "4a6cdf3e-b401-45aa-9c33-7ebb6cd4923d",
        name : "Endpoints",
        url : "/admin/endpoints",
        icon : "icon-info",
        children : [

        ]
    },
    {
        _id : "b86ccab4-2044-4ded-bb45-96c98649e5a3",
        name : "Video Wall",
        url : "/video-wall",
        icon : "icon-camrecorder",
        children : [

        ]
    },
    {
        _id : "ab92025e-8c3f-4aa8-8e7a-76ad5f41a10b",
        name : "FIDS",
        url : "/fids/manage",
        icon : "icon-plane"
    },
    {
        _id : "a94bade4-f62d-4be1-ada5-f11f06b515a2",
        name : "FIDS Create",
        url : "/fids/create",
        icon : "icon-plus"
    },
    {
        _id : "326af67c-cfec-496d-8de3-461209c687a1",
        name : "Charts",
        url : "/charts",
        icon : "icon-pie-chart"
    },
    {
        _id : "b8eeafde-5ce7-4e38-b1fe-9efdd00f6e47",
        children : [
            {
                name : "Basic Forms",
                url : "/forms/basic-forms",
                icon : "icon-note"
            },
            {
                name : "Advanced Forms",
                url : "/forms/advanced-forms",
                icon : "icon-note"
            },
            {
                name : "Validation",
                url : "/forms/validation-forms",
                icon : "icon-note"
            }
        ],
        name : "Forms",
        url : "/forms",
        icon : "icon-note"
    },
    {
        _id : "c9184fd1-66c5-4fd3-be28-ea1fad4375db",
        children : [
            {
                name : "CoreUI Icons",
                url : "/icons/coreui-icons",
                icon : "icon-star"
            },
            {
                name : "Flags",
                url : "/icons/flags",
                icon : "icon-star"
            },
            {
                name : "Font Awesome",
                url : "/icons/font-awesome",
                icon : "icon-star"
            },
            {
                name : "Simple Line Icons",
                url : "/icons/simple-line-icons",
                icon : "icon-star"
            }
        ],
        name : "Icons",
        url : "/icons/",
        icon : "icon-star"
    },
    {
        _id : "4f30fa33-26c7-4a35-987c-ddea9b582660",
        children : [

        ],
        name : "Logout",
        url : "/pages/login",
        icon : "icon-star"
    },
    {
        _id : "bd81119a-d9b0-4f02-ae19-23a90a79c3bc",
        children : [

        ],
        name : "Register",
        url : "/pages/register",
        icon : "icon-star"
    },
    {
        _id : "5c72c070-0bfb-4dc4-ab0f-80a335626edd",
        children : [

        ],
        name : "Camera Functions",
        url : "/camera/functions",
        icon : "fa fa-arrows"
    },
    {
        _id : "64c7155d-4b21-4b1c-88a2-928b81ea19e1",
        children : [

        ],
        name : "Intact",
        url : "/intact-case",
        icon : "fa fa-briefcase"
    },
    {
        _id : "81613580-2397-43a4-80dc-cd3ca8331fa8",
        children : [

        ],
        name : "Configs",
        url : "/config",
        icon : "fa fa-database"
    },
    {
        _id : "13edcab9-ac65-4863-9db6-76daa3be2241",
        children : [
            {
                name : "Panel",
                url : "/ids/panel",
                icon : "icon-star"
            },
            {
                name : "Sites",
                url : "/ids",
                icon : "icon-star"
            },
            {
                name : "Site Details",
                url : "/ids/sitedetails",
                icon : "icon-star"
            }
        ],
        name : "IDS",
        url : "/ids",
        icon : "icon-star"
    },
    {
        _id : "0063e325-7b3b-4548-9a1c-6f43b304de2d",
        children : [

        ],
        name : "ISS Permissions",
        url : "/iss-permissions",
        icon : "fa fa-lock"
    },
    {
        _id : "e229b5ed-c29d-418e-ab16-35af0e26e797",
        children : [

        ],
        name : "Event Manager",
        url : "/event-manager",
        icon : "icon-star"
    },
    {
        _id : "95889bc8-4a9d-40b4-a343-3ea001495257",
        children : [
            {
                name : "Overview",
                url : "/yardtracks",
                icon : "icon-star"
            },
            {
                name : "Reports",
                url : "/yardtracks/reports",
                icon : "icon-star"
            },
            {
                name : "LPR",
                url : "/lpr",
                icon : "icon-star"
            }
        ],
        name : "YardTrack",
        url : "/yardtrack",
        icon : "icon-star"
    },
    {
        _id : "bcd55c91-4be6-4ca4-aec0-cbacee99f265",
        children : [

        ],
        name : "ABC Sites",
        url : "/abc/sites",
        icon : "fa fa-home"
    },
    {
        _id : "780b2d1c-c2f3-449a-a632-8d5b739d6212",
        children : [

        ],
        name : "OP Portal",
        url : "/ids/monitoring",
        icon : "icon-star"
    },
    {
        _id : "c4aab2b2-8879-45bc-b951-84e0c4e91be5",
        children : [

        ],
        name : "FIDS Airlines",
        url : "/fids/airlines",
        icon : "fa fa-briefcase"
    },
    {
        _id : "906852e5-20c2-4c05-a9c0-5f5d567b9994",
        children : [

        ],
        name : "Pitts Controller",
        url : "/pitts",
        icon : "fa fa-gift"
    },
    {
        _id : "a9677b89-2b22-4aca-9ccb-04fc4bdf1245",
        children : [

        ],
        name : "Exports",
        url : "/exports",
        icon : "fa fa-folder-open-o"
    },
    {
        _id : "11f41c69-0275-4e02-b702-6ff8420f72a8",
        children : [
            {
                name : "monitoring",
                url : "/demo/rha",
                icon : "icon-star"
            },
            {
                name : "mustering",
                url : "/demo/abc/mustering",
                icon : "icon-star"
            },
            {
                name : "access control",
                url : "/demo/abc/accessControl",
                icon : "icon-star"
            },
            {
                name : "kiosk",
                url : "/demo/abc/kiosk",
                icon : "icon-star"
            }
        ],
        name : "demos",
        url : "/demo",
        icon : "icon-star"
    },
    {
        _id : "96e854ef-ee2e-4258-abf6-5f69fef7b08d",
        children : [

        ],
        name : "Gim",
        url : "/gim",
        icon : "icon-star"
    },
    {
        _id : "7798226f-41dc-4b53-946b-682496e928ed",
        children : [
            {
                name : "DIS Overview",
                url : "/dis/overview",
                icon : "fa fa-list"
            },
            {
                name : "Images",
                url : "/images",
                icon : "fa fa-picture-o"
            }
        ],
        name : "DIS",
        url : "/dis/overview",
        icon : "fa fa-television"
    },
    {
        _id : "1786e01f-9803-4c86-a939-23af655570e5",
        children : [
            {
                name : "Edit Constellations",
                url : "/admin/constellations",
                icon : "icon-wrench"
            },
            {
                name : "Configs",
                url : "/config",
                icon : "fa fa-database"
            },
            {
                name : "Kepler",
                url : "/admin/kepler",
                icon : "icon-disc"
            },
            {
                name : "Claims",
                url : "/admin/claims",
                icon : "fa fa-spin fa-space-shuttle"
            },
            {
                name : "Satellite Permissions",
                url : "/admin/permissions",
                icon : "icon-shield"
            },
            {
                name : "Notifications",
                url : "/admin/notifications",
                icon : "fa fa-exclamation"
            }
        ],
        name : "Admin Tools",
        url : "/admin",
        icon : "fa-spin icon-settings"
    },
    {
        _id : "304fbfb6-8687-4032-aad2-111a054e420c",
        children : [
            {
                name : "RISC Profiles",
                url : "/risc/profiles",
                icon : "icon-people"
            },
            {
                name : "RISC Organizations",
                url : "/risc/organizations",
                icon : "fa fa-sitemap"
            },
            {
                name : "RISC Intel Case",
                url : "/risc/intelcases",
                icon : "fa fa-folder-o"
            }
        ],
        name : "RISC",
        url : "/risc/profiles",
        icon : "fa fa-shield"
    },
    {
        _id : "9d527069-8890-4e6b-80a9-83d52a2e3dde",
        children : [

        ],
        name : "Supervisor View",
        url : "/digitalevidence/supervisor",
        icon : "fa fa-tasks"
    },
    {
        _id : "5dc9ba0c-314c-4c35-831c-a713c5b91239",
        children : [

        ],
        name : "User View",
        url : "/digitalevidence/user",
        icon : "fa fa-file-archive-o"
    },
    {
        _id : "a196c4cb-5236-4524-94db-bb5cea4e651c",
        children : [

        ],
        name : "Upload Evidence",
        url : "/digitalevidence/upload",
        icon : "fa fa-upload"
    },
    {
        _id : "c20c83c2-4269-4133-a805-d4347f43dbb6",
        children : [

        ],
        name : "Evidence View",
        url : "/digitalevidence/evidence",
        icon : "fa fa-picture-o"
    },
    {
        _id : "e5dd3a33-2720-4bd6-9e4f-81b07bbaae0f",
        children : [
            {
                name : "Evidence View",
                url : "/digitalevidence/evidence",
                icon : "fa fa-picture-o"
            },
            {
                name : "Supervisor View",
                url : "/digitalevidence/supervisor",
                icon : "fa fa-tasks"
            },
            {
                name : "Upload Evidence",
                url : "/digitalevidence/upload",
                icon : "fa fa-upload"
            },
            {
                name : "User View",
                url : "/digitalevidence/user",
                icon : "fa fa-file-archive-o"
            }
        ],
        name : "Digital Evidence",
        url : "/digitalevidence/evidence",
        icon : "fa fa-server"
    },
    {
        _id : "9e75eadf-21df-4b22-b3ae-a33c446485bc",
        children : [

        ],
        name : "Map View",
        url : "/map-view",
        icon : "fa fa-map-marker"
    }
]*/
