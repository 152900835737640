import gammaStore from "@/store/gammaStore";
import SmartSuiteArchive from "@/shared/smartsuite_services/smartsuite_archive";

const AddUser = 0;
const RemoveUser = 1;
const AddCredentials = 2;
const ManageDevice = 3;
const StartArchive = 4;
const StopArchive = 5;
const SetDeviceName = 6;
const SetStreamName = 7;
const RebootDevice = 8;
const SetGeoLocation = 9;

function SmartSuiteMedia(device, tenant) {
    this.gamma = null;
    this.device = device;
    this.tenantId = tenant ? tenant._id : device.getTenantId()
}

SmartSuiteMedia.prototype.connect = async function () {
    this.gamma = await gammaStore.dispatch('getMediahub', this.tenantId);
}

SmartSuiteMedia.prototype.close = async function () {
    await gammaStore.dispatch('closeMediahub', this.tenantId);
}

SmartSuiteMedia.prototype.updateDevice = function () {
    this.setDeviceName();
    this.setStreamName();
    this.setGeoLocation();
}

SmartSuiteMedia.prototype.setDeviceName = function () {
    let message = {
        commandRequest: SetDeviceName,
        deviceSerialNUmber: this.device.getSerialNumber(),
        friendlyName: this.device.getDeviceName(),
        hubCommandChannel: this.device.getHubName()+ '_command'
    };
    this.gamma.sendMessage('sendusermessage', message);
    if (this.device.getMultiSensorStreams().length === 1) {
        let streams = this.device.getAllSourceQualities('0');
        streams.forEach(stream => {
            let message2 = {
                commandRequest: SetStreamName,
                deviceSerialNumber: this.device.getSerialNumber(),
                friendlyName: this.device.getDeviceName(),
                dataChannel: stream.dataChannel,
                hubCommandChannel: this.device.getDevice().hubName + "_command"
            };
            this.gamma.sendMessage('sendusermessage', message2);
        });
    }
}

SmartSuiteMedia.prototype.setStreamName = function () {
    if (this.device.getMultiSensorStreams().length > 1) {
        for (let i = 0; i < this.device.getMultiSensorStreams().length; i++) {
            //get all the streams with the same source token
            let streams = this.device.getAllSourceQualities(i.toString());
            //find the stream with the highest quality since that is the one getting renamed
            let renamedStream = streams.reduce((maxQuality, currentStream) => {
                return currentStream.streamQuality > maxQuality.streamQuality ? currentStream : maxQuality;
            }, streams[0]);
            //rename each stream of the same source token to that name
            streams.forEach(stream => {
                let message = {
                    commandRequest: SetStreamName,
                    deviceSerialNumber: this.device.getSerialNumber(),
                    friendlyName: renamedStream.friendlyName,
                    dataChannel: stream.dataChannel,
                    hubCommandChannel: this.device.getDevice().hubName + "_command"
                };
                this.gamma.sendMessage('sendusermessage', message);
            });
        }
    }
}

SmartSuiteMedia.prototype.setGeoLocation = function () {
    let message = {
        commandRequest: SetGeoLocation,
        deviceSerialNumber: this.device.getSerialNumber(),
        hubCommandChannel: this.device.getHubName() + "_command",
        commandArgs: this.device.getLatitude() +':'+this.device.getLongitude()
    };
    this.gamma.sendMessage('sendusermessage', message);
}

SmartSuiteMedia.prototype.setMediahubListener = async function (callback) {
    await gammaStore.dispatch('setMediahubListener', {
        _id: this.tenantId, event: 'hubannouncements', callback: (event => {
            callback(event);
        })
    })
}

export default SmartSuiteMedia;