import axios from 'axios';

function Constellation(url) {
    return axios.create({
        baseURL: "https://" + url,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + get_constellation_token()
        }
    });
}

function get_constellation_token() {
    return localStorage.oldToken;
}

export default {
    cameraFunction(url, data) {
        return new Constellation(url).post("/camera/function", data);
    },
    createFloorPlan(url, data) {
        return new Constellation(url).post("/cac/floorplans", data);
    },
    createDevice(url, data) {
        return new Constellation(url).post("/device", data)
    },
    createObjectScanVoyager(url) {
        return new Constellation(url).post("/voyager/identify");
    },
    createObjectScanScan(url, data) {
        return new Constellation(url).post("/voyager/scan", data);
    },
    exportVideoFromSD(deviceId, url, startTime, endTime) {
        return new Constellation(url).get("/device/sd/export/" + deviceId, {
            params: {
                starttime: startTime,
                stoptime: endTime
            },
            responseType: "blob"
        });
    },
    readConstellationConfig(url) {
        return new Constellation(url).get("/elasticband/config");
    },
    //TODO Finish removing calls in favor of using store
    readDevice(url, id) {
        return new Constellation(url).get("/device/" + id);
    },
    readDeviceList(url) {
        return new Constellation(url).get("/device/list");
    },
    readDeviceCameraList(url, timeout) {
        timeout = timeout || 0;
        return new Constellation(url).get('/device/camera/list', {timeout: timeout});
    },
    readUnidentifiedDevices(url) {
        return new Constellation(url).get("/device/unidentified/list")
    },
    readFloorPlans(url) {
        return new Constellation(url).get("/cac/floorplans");
    },
    moveCamera(url, data) {
        return new Constellation(url).post("/camera/function", data);
    },
    updateDeviceEndpoints(url, id, data) {
        return new Constellation(url).put('/device/endpoints/' + id, data);
    },
    updateConstellationConfig(url, data) {
        return new Constellation(url).put("/elasticband/config/" + data._id, {
            retention: data.retention,
            retention_min: data.retention_min,
            disk_percentage_max: data.disk_percentage_max,
            hold: data.hold
        });
    },
    updateDevice(url, data) {
        return new Constellation(url).put("/device/" + data._id, data);
    },
    updateFloorPlan(url, data) {
        return new Constellation(url).put("/cac/floorplans/" + data.id, {
            image: data.image,
            description: data.description,
            devices: data.devices,
            name: data.name,
            id: data.id
        });
    },
    deleteDevice(url, id) {
        return new Constellation(url).delete("/device/" + id);
    },

    /* DIS */
    createDIS(url, data) {
        return new Constellation(url).post("/dis", data)
    },
    createDISImage(url, data) {
        return new Constellation(url).post("/image", data)
    },
    readDIS(url, devId) {
        return new Constellation(url).get("/dis/" + devId);
    },
    readAllDIS(url) {
        return new Constellation(url).get("/dis");
    },
    readDISImages(url) {
        return new Constellation(url).get("/image");
    },
    readDISImage(url, id) {
        return new Constellation(url).get("/image/" + id)
    },
    updateDISDevice(url, id, data) {
        return new Constellation(url).put("/dis/" + id, data);
    },
    updateDISImage(url, id, data) {
        return new Constellation(url).put("/image/" + id, data);
    },
    deleteDIS(url, id) {
        return new Constellation(url).delete("/dis/" + id);
    },
    deleteDISImage(url, id) {
        return new Constellation(url).delete("/image/" + id);
    },

    /* Permissions */
    getPermissions(url) {
        return new Constellation(url).get("/permission/list");
    },
    putPermissions(url, data) {
        return new Constellation(url).put("/permission/many/add", data);
    },
    deletePermissions(url, data) {
        return new Constellation(url).put("/permission/many/remove", data)
    },

    /* Microservices */
    getMicroservices(url) {
        return new Constellation(url).get("/microservice");
    },
    getMicroservice(url, id) {  // Doesn't give any more information than getMicroservices
        return new Constellation(url).get("/microservice/" + id);
    },
    restartMicroservice(url, id) {
        return new Constellation(url).post("/microservice/restart/" + id);
    },
    stopMicroservice(url, id) {
        return new Constellation(url).post("/microservice/stop/" + id);
    },
    removeMicroservice(url, id) {
        return new Constellation(url).post("/microservice/remove/" + id);
    },
    startElasticBandCleanup(url) {
        return new Constellation(url).post("/elasticband/cleanup/start/all");
    },
    stopElasticBandCleanup(url) {
        return new Constellation(url).post("/elasticband/cleanup/stop/all");
    },
    restartElasticBandCleanup(url) {
        return new Constellation(url).post("/elasticband/cleanup/restart/all");
    },
    removeElasticBandCleanup(url) {
        return new Constellation(url).post("/elasticband/cleanup/remove/all");
    },
    startSnmp(url) {
        return new Constellation(url).post("/fm/snmp/start/all");
    },
    stopSnmp(url) {
        return new Constellation(url).post("/fm/snmp/stop/all");
    },
    restartSnmp(url) {
        return new Constellation(url).post("/fm/snmp/restart/all");
    },
    removeSnmp(url) {
        return new Constellation(url).post("/fm/snmp/remove/all");
    },
    startElasticBand(url) {
        return new Constellation(url).post("/elasticband/start/all");
    },
    stopElasticBand(url) {
        return new Constellation(url).post("/elasticband/stop/all");
    },
    restartElasticBand(url) {
        return new Constellation(url).post("/elasticband/restart/all");
    },
    removeElasticBand(url) {
        return new Constellation(url).post("/elasticband/remove/all");
    },
    startPing(url) {
        return new Constellation(url).post("/fm/ping/start/all");
    },
    stopPing(url) {
        return new Constellation(url).post("/fm/ping/stop/all");
    },
    restartPing(url) {
        return new Constellation(url).post("/fm/ping/restart/all");
    },
    removePing(url) {
        return new Constellation(url).post("/fm/ping/remove/all");
    },

    /* Forensic Monitoring */
    first(url, deviceId, time) {
        return new Constellation(url).get("/fm/snmp/list/" + deviceId + "?time=" + time)
    },
    second(url, deviceId, time, oid) {
        return new Constellation(url).get("/fm/snmp/" + deviceId + "/history?time=" + time + "&oid=" + oid)
    },
    third(url, deviceId, time) {
        return new Constellation(url).get("/fm/ping/list/" + deviceId + "?time=" + time)
    },

    /* LPR */
    readLprDownsize(url, lpr) {
        return "https://" + url + "/lpr/retrieve/downsize/" + lpr + "?height=120&width=214&tokenId=" + get_constellation_token();
    },
    readLprFullsize(url, lpr) {
        return "https://" + url + "/lpr/retrieve/fullsize/" + lpr + "?tokenId=" + get_constellation_token();
    },
    readLprLatest(url) {
        return new Constellation(url).get("/lpr/latest/10");
    },
    readLprSearch(url, search) {
        return new Constellation(url).get("/lpr/search/" + search);
    },
    readLprStat(url) {
        return new Constellation(url).get("/lpr/stat");
    },
    get_constellation_token
}