<template>
    <div style="position: relative; color: white;">

        <div v-if="false" @click="redoNotification()" style="position: absolute; z-index: 10000; pointer-events: none" class="d-flex justify-content-center w-100" @click.>
            <b-badge variant="danger" size="lg" style="font-size: 14px; margin: 8px; pointer-events: all" class="offlineNotification">
                <div style="font-size: 14px; font-weight: bolder; margin-bottom: 3px">Server Unreachable</div>
                <div style="font-size: 12px">Check your Internet Connection</div>
            </b-badge>
        </div>

        <div v-if="teapotModal" v-show="!duoModal" style="position: absolute; z-index: 1000; height: 100vh; width: 100vw; backdrop-filter: blur(5px);" class="d-flex align-items-center justify-content-center">
            <div style="background-color: black; padding: 15px; border: 1px solid white" class="d-flex flex-column align-items-center rounded">
                <h3><b>Re-Login</b></h3>
                <b-input-group style="margin-bottom: 10px; height: auto">
                    <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"/></b-input-group-text>
                    </b-input-group-prepend>
                    <input @keyup.enter="teapotFunction" v-model="username" type="text" class="form-control" placeholder="Username">
                </b-input-group>
                <b-input-group style="height: auto">
                    <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"/></b-input-group-text>
                    </b-input-group-prepend>
                    <input @keyup.enter="teapotFunction" v-model="password" type="password" class="form-control" placeholder="Password">
                </b-input-group>
                <div v-if="teapotAttemptCount !== 0" style="color: red; font-size: 12px">
                    Invalid Credentials: {{ 3 - teapotAttemptCount }} attempt(s) remaining
                </div>
                <div class="d-flex" style="padding-top: 10px">
                    <b-button style="margin-left: 3px;" @click="teapotFunction" class="bg-primary">Login</b-button>
                </div>
            </div>
        </div>


        <div v-if="duoModal" style="position: absolute; z-index: 1001; height: 100vh; width: 100vw" class="d-flex align-items-center justify-content-center">
            <div style="background-color: black; padding: 15px; border: 1px solid white" class="d-flex flex-column align-items-center rounded">
                <h3><b><fa-icon :icon="['fas', 'lock']"/>&nbsp;Two Factor Authentication</b></h3>
                <b>Enter 6 digit code</b>
                <div class="d-flex" style="padding-bottom: 0">
                    <b-form-input style="text-align: center; width: 11.95em; letter-spacing: 1em" :style="duoCode.length === 0 ? 0 : 'text-indent: 1em'"
                                  v-model="duoCode" @keyup.enter="submitDuo" maxlength="6" @keypress="onlyNumber" autofocus/>
                </div>
                <div v-if="duoAttemptCount !== 0" style="color: red; font-size: 12px">
                    Incorrect Code: {{ 3 - duoAttemptCount }} attempt(s) remaining
                </div>
                <div class="d-flex" style="padding-top: 10px">
                    <b-button style="margin-right: 3px;" @click="duoModal=false" class="bg-info">Cancel</b-button>
                    <b-button style="margin-left: 3px;" @click="submitDuo" class="bg-primary" :disabled="duoCode.length !== 6">Submit</b-button>
                </div>
            </div>
        </div>

        <router-view ref="defaultContainer"></router-view>
    </div>
</template>

<script>
import axios from 'axios';
import decode from 'jwt-decode';
import Vue from 'vue';
import toasterPlugin from "@/plugins/toasterPlugin";

Vue.use(toasterPlugin)

export default {
    data() {
        return {
            isOnline: true,
            teapotModal: false,
            username: "",
            password: "",
            duoModal: false,
            duoCode: "",
            teapoting: false,
            duoAttemptCount: 0,
            teapotAttemptCount: 0,
            options: {
                defaultDelay: 3000,
            },
        }
    },
    updated() {
        if (this.$route.query && this.$route.query.teapoting === 'true') {
            // this.$refs.defaultContainer.cubes = false;
            this.$refs.defaultContainer.loaded = false;
            this.teapoting = true;
            this.teapotModal = true;
        }
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        teapotFunction() {
            this.teapotModal = false
            localStorage.clear();
            //login
            axios({
                method: "POST",
                "url": process.env.VUE_APP_ISSENGINE + '/auth/login',
                "data": {"username": this.username, "password": this.password}
            }).then(() => {
                this.teapotModal = false;
                this.duoCode = "";
                this.duoModal = true;
                this.duoAttemptCount = 0;
            }, () => {
                this.teapotAttemptCount++;
                if (this.teapotAttemptCount === 3) {
                    window.location = '/#/pages/login';
                    location.reload();
                }
            })
        },
        submitDuo() {
            axios({
                method: "POST",
                "url": process.env.VUE_APP_ISSENGINE + '/auth/verify/twofactor',
                "data": {"username": this.username, "password": this.password, twoFactor: this.duoCode}
            }).then(response => {
                let token = decode(response.data.token);
                let oldToken = decode(response.data.oldToken);
                localStorage.setItem('tokenStatus', token.status);
                localStorage.setItem('mtopToken', response.data.token);
                localStorage.setItem('email', token.email);
                localStorage.setItem('type', token.type);
                localStorage.setItem('username', token.username);
                localStorage.setItem('id', token._id);
                localStorage.setItem('groups', token.groups);
                localStorage.setItem('firstname', token.firstname);
                localStorage.setItem('lastname', token.lastname);
                localStorage.setItem('oldToken', response.data.oldToken);
                localStorage.setItem('tokenExp', token.tokenExpiration);
                localStorage.setItem('reauthToken', token.reauthToken);
                localStorage.setItem('customFields', JSON.stringify(token.customFields));
                localStorage.setItem('started', "false");
                this.duoModal = false;
                this.teapoting = true;
                window.location = window.location.href.split("?")[0];
                location.reload();
            }, () => {
                this.duoAttemptCount++;
                if (this.duoAttemptCount === 3) {
                    window.location = '/#/pages/login';
                    location.reload();
                }
            })
        },
        preventClose(e) {
            e.preventDefault();
            return false;
        }
    }
}

</script>

<style src="bootstrap/dist/css/bootstrap.css"></style>
<style src="font-awesome/css/font-awesome.min.css"></style>
<style src="./../node_modules/vue-multiselect/dist/vue-multiselect.min.css"/>
<style>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>
<style scoped>
.offlineNotification {
    animation: dropFromTop 1s;
}
@keyframes dropFromTop {
    0% {
        transform: translateY(-44px);
    }
    100% {
        transform: translateY(0px);
    }
}
</style>
<style lang="scss">
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */
/*$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';*/
/* Import Simple Line Icons Set */
$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
/* Import Flag Icons Set */
@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */
@import '~bootstrap-vue/dist/bootstrap-vue.css';
// Import Main styles for this application
@import 'assets/scss/style';
.dataTable {
    width: 95%;
    margin: 0 auto;

    .VuePagination {
        text-align: center;
        justify-content: center;
    }

    .vue-title {
        text-align: center;
        margin-bottom: 10px;
    }

    .VueTables__search-field {
        display: flex;
    }

    .VueTables__search-field input {
        margin-left: 0.25rem;
    }

    .VueTables__limit-field {
        display: flex;
    }

    .VueTables__limit-field select {
        margin-left: 0.25rem !important;
    }

    .VueTables__table th {
        text-align: left;
        color: white;
    }

    .VueTables__table td {
        color: white;
    }

    .VueTables__limit-field select{
        color: white;
    }

    .VueTables__child-row-toggler {
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: block;
        margin: auto;
        text-align: center;
    }

    .VueTables__child-row-toggler--closed::before {
        content: "+";
    }

    .VueTables__child-row-toggler--open::before {
        content: "-";
    }
}
</style>
<style type="text/css">
@import 'assets/css/main.css';

@media print {

    body {
        margin: 0;
        color: #000;
        background-color: #fff;
    }

    ol.breadcrumb, footer.app-footer {
        display: none;
    }

    /*
    12 pt font
    Serif font?
    modify padding and margins with cm, mm, in
    use borders instead of background-colors
    */

}

.non-link {
    color: #e4e7ea;
}

.non-link:hover {
    color: #c8ced3;
    text-decoration: none;
}

i {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

::-webkit-scrollbar-thumb {
    background: #2f353a;
    border: 0 none #ffffff;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: #2f353a;
}

::-webkit-scrollbar-thumb:active {
    background: #2f353a;
}

::-webkit-scrollbar-track {
    background: #23292e;
    border: 0 none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #23292e;
}

::-webkit-scrollbar-track:active {
    background: #23292e;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
</style>