const UserList = () => import('@/views/admin/user_management/Users');
const NavTabs = () => import('@/views/admin/satellite_management/NavTabs');
const Constellations = () => import('@/views/admin/constellation_management/Constellations');
const Permissions = () => import('@/views/admin/satellite_management/SatellitePermissions');
const Kepler = () => import('@/views/admin/kepler/Kepler');
const ConstellationPermissions = () => import('@/views/admin/constellation_management/ConstellationPermissions');
const Endpoints = () => import('@/views/admin/Endpoint');
const Microservices = () => import('@/views/admin/constellation_management/Microservices');
const ForensicMonitoring = () => import('@/views/admin/ForensicMonitoring');
const Config = () => import('@/views/admin/constellation_management/Config');
const Notifications = () => import('@/views/admin/satellite_management/Notifications');
const Claims = () => import('@/views/admin/user_management/Claims');
const Groups = () => import('@/views/admin/user_management/Groups');
const IssPermissions = () => import('@/views/admin/IssPermissions');
const ProfileOverview = () => import('@/views/admin/user_management/ProfileOverview');
const UserSettings = () => import('@/views/admin/user_management/UserSettings');
const MapView = () => import('@/views/map_view/MapView');
const Pitts = () => import('@/views/admin/pitts/Controller');
const Services = () => import('@/views/admin/Services');
const CameraFunctions = () => import ('@/views/admin/CameraFunctions');

export default [
    {
        path: '/user-settings',
        name: 'User Settings',
        component: UserSettings
    },
    {
        path: '/groups',
        name: 'Groups',
        component: Groups
    },
    {
        path: '/admin/users',
        name: 'User List',
        component: UserList
    },
    {
        path: '/admin/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '/admin/claims',
        name: 'Claims',
        component: Claims
    },
    {
        path: '/admin/nav-tabs',
        name: 'Nav Tabs',
        component: NavTabs
    },
    {
        path: '/admin/kepler',
        name: 'Kepler',
        component: Kepler
    },
    {
        path: '/admin/constellations',
        name: 'Admin Constellations',
        component: Constellations
    },
    {
        path: '/admin/microservices/:constellationId',
        name: 'Microservices',
        component: Microservices,
        props: true,
    },
    {
        path: '/admin/permissions',
        name: 'Permissions',
        component: Permissions
    },
    {
        path: '/iss-permissions',
        name: 'ISS Permissions',
        component: IssPermissions
    },
    {
        path: '/admin/constellations/:constellationId',
        name: 'Constellation Permissions',
        component: ConstellationPermissions,
        props: true
    },
    {
        path: '/forensic-monitoring/:constellationId/:deviceId',
        name: 'Forensic Monitoring',
        component: ForensicMonitoring,
        props: true
    },
    {
        path: '/config',
        name: 'Config',
        component: Config,
    },
    {
        path: '/admin/endpoints',
        name: 'Endpoints',
        component: Endpoints,
    },
    {
        path: '/map-view',
        name: 'Map View',
        component: MapView
    },
    {
        path: 'profile-overview',
        name: 'Profile Overview',
        component: ProfileOverview
    },
    {
        path: '/pitts',
        name: 'Pitts Controller',
        component: Pitts
    },
    {
        path:'/services',
        name: 'Services',
        component: Services
    },
    {
        path: '/camera/functions',
        name: 'Camera Functions',
        component: CameraFunctions
    }
];