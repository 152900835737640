const Templates = () => import('@/views/expansion_modules/reports/template_components/Templates');
const Template = () => import('@/views/expansion_modules/reports/template_components/Template');

const Cases = () => import('@/views/expansion_modules/reports/case_components/Cases');
const Case = () => import('@/views/expansion_modules/reports/case_components/Case');
const SupervisorView = () => import('@/views/expansion_modules/reports/SupervisorView');

const Reports = () => import('@/views/expansion_modules/reports/Reports');

export default [
    {
        path: '/templates',
        redirect: '/templates',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/template/:id',
                name: 'Template',
                component: Template,
                props: true
            },
            {
                path: '/templates',
                name: 'Templates',
                component: Templates,
                props: true
            }
        ]
    },
    {
        path: '/reports',
        redirect: '/reports',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path:'/reports',
                name: 'Reports',
                component: Reports,
                props: true
            }
        ]
    },
    {
        path: '/cases',
        redirect: '/cases',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/cases',
                name: 'Cases',
                component: Cases
            },
            {
                path: '/cases/supervisor',
                name: 'Supervisor ',
                component: SupervisorView
            },
            {
                path: '/case/:caseId',
                name: 'Case',
                component: Case,
                props: true
            },
            {
                path: '/case/:caseId/:reportId',
                name: 'Case ',
                component: Case,
                props: true
            }
        ]
    },
]