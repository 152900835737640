﻿
import deviceStore from "@/store/deviceStore";
import PubSub from 'pubsub-js';
import SmartSuiteLivestream from "../smartsuite_services/smartsuite_livestream";

const debug = false;

function functionErr(str) {
    console.error('Error: Method \'' + str + '\' is not implemented yet')
}


function JmuxPlayer(device, element, quality, sourceToken, dataChannel, archiveOnly) {
    if (debug) console.log('Method:', 'JmuxPlayer')
    this.device = device;
    this.sourceToken = sourceToken;
    this.dataChannel = dataChannel;
    this.element = element;
    this.quality = quality;
    this.playerId = device.getDeviceId() + sourceToken + dataChannel;
    this.archiveOnly = archiveOnly;
    if (archiveOnly === true) {
        this.stream = {streamServer: device.getStreamServer()};
    } else if (!quality && quality !== 0) {
        this.stream = device.findQuality(device.getStreams(), deviceStore.getters.getQuality, device, sourceToken, dataChannel);
    } else {
        this.stream = device.findQuality(device.getStreams(), quality, device, sourceToken, dataChannel);
    }
    this.ws = null;
    this.jmuxer = null;
    this.currentTime = 0;
    this.mode = null;
    this.isContinuous = null;
    this.paused = false;

    this.jmuxerWorker = new Worker('/js/JmuxerWebWorker.js', {name: this.playerId/*, type: 'module'*/});
    this.jmuxerWorker.addEventListener('error', event => {
        console.error('Error stack trace:', event.filename, event.lineno, event.colno);
        console.error('Error in worker:', event.message);
        console.error('Error:', event);
    })
    this.jmuxerWorker.postMessage({method: 'isArchive', args: this.device.archive})
    this.jmuxerWorker.postMessage({
        method: 'setStream',
        args: this.getStream(device, quality, sourceToken, dataChannel, archiveOnly)
    });
    this.workerVars = {
        livePlay: false,
        isPaused: true,
        currentTime: 0,
        currentFrameBufferTime: null
    }

    this.jmuxerWorker.onmessage = async (e) => {

        const {method, args} = e.data;

        switch (method) {
            case 'updateVar':
                let key = args[0];
                let value = args[1];
                this.workerVars[key] = value;
                break;
            case 'startPlayingVideo':
                document.getElementById(this.element).play().catch(() => {});
                break;
            case 'handle':
                document.getElementById(this.element).srcObject = args;
                break;
            case 'setCanvasErrorMessage':
                this.device.setCanvasErrorMessage(this.playerId, ...args);
                break;
            case 'setCanvasLoadingStatus':
                this.device.setCanvasLoadingStatus(this.playerId, args);
                break;
            case 'displayDecodedVideo':
                PubSub.publish('displayDecodedVideo' + this.playerId);
                break;
            case 'videoBuffering':
                PubSub.publish('videoBuffering' + this.playerId, args);
                break;
            case 'setBufferStartTime':
                PubSub.publish('setBufferStartTime', args)
                break;
            case 'request_next_archive_segment':
                PubSub.publish('request_next_archive_segment', args)
                break;
            case 'archiveStreamingDone':
                PubSub.publish('archiveStreamingDone');
                break;
            case 'livestreamPlaying':
                PubSub.publish('livestreamPlaying' + this.playerId);
                break;
            case 'createSSLiveStream':
                await this.createSSLiveStream(...args);
                break;
            case 'removeLoading':
                this.removeLoading()
                break;
            case 'findQuality':
                this.findQuality()
                break;
            case 'new_jmuxer':
                this.new_jmuxer(...args)
                break;
            case 'log':
                console.log(...args)
                break;
            default:
                console.error('Error: method "' + method + '" doesn\'t exist')
        }

    }
}

JmuxPlayer.prototype.getStream = function(device, quality, sourceToken, dataChannel, archiveOnly) {
    if (debug) console.log('Method:', 'getStream')
    if (archiveOnly === true) {
        return {streamServer: device.getStreamServer()};
    } else if (!quality && quality !== 0) {
        return device.findQuality(device.getStreams(), deviceStore.getters.getQuality, device, sourceToken, dataChannel);
    }
    return device.findQuality(device.getStreams(), quality, device, sourceToken, dataChannel);
}

JmuxPlayer.prototype.new_jmuxer = function(stream, delay, clearBuffer, flushingTime, mode, request) {
    if (debug) console.log('Method:', 'new_jmuxer')
    this.jmuxerWorker.postMessage({method: 'newJMuxer', args: [stream, delay, clearBuffer, flushingTime, mode, request]})
}

JmuxPlayer.prototype.seek = function(time) {
    if (debug) console.log('Method:', 'seek')
    let video = document.getElementById(this.element)
    video.currentTime = time;
}

JmuxPlayer.prototype.createSSLiveStream = async function(stream, retryBoolean, forceRestart) {
    if (debug) console.log('Method:', 'createSSLiveStream', stream)
    let ssLiveStream = new SmartSuiteLivestream(this.device);
    await ssLiveStream.connect();
    await ssLiveStream.refresh();
    await ssLiveStream.getLiveStreamRequest(stream, requestId => {
        this.jmuxerWorker.postMessage({method: 'ssLiveStreamCallback', args: [stream, requestId]});
    });
}

JmuxPlayer.prototype.removeLoading = function () {
    if (debug) console.log('Method:', 'removeLoading')
    if (this.device.getCanvasLoadingStatus(this.playerId) === true) {
        this.device.setCanvasLoadingStatus(this.playerId, false);
    }
    this.device.setCanvasErrorMessage(this.playerId, '', '');
}

JmuxPlayer.prototype.destroyWebSocket = function(framesGrabbedBool) {
    if (debug) console.log('Method:', 'destroyWebSocket')
    this.jmuxerWorker.postMessage({method: 'destroyWebSocket', args: framesGrabbedBool});
}

JmuxPlayer.prototype.findQuality = function() {
    if (debug) console.log('Method:', 'findQuality')
    this.jmuxerWorker.postMessage({
        method: 'setStream',
        args: this.device.findQuality(this.device.getStreams(), this.quality, this.device, this.sourceToken, this.dataChannel)
    });
}

JmuxPlayer.prototype.destroy = function() {
    if (debug) console.log('Method:', 'destroy')
    this.destroyWebSocket();
    this.jmuxerWorker.postMessage({method: 'destroy'});
    this.jmuxerWorker.terminate();
}

JmuxPlayer.prototype.getCurrentTime = function() {
    if (debug) console.log('Method:', 'getCurrentTime')
    return document.getElementById(this.element).currentTime;
}

JmuxPlayer.prototype.start = function(playerId, request, isContinuous) {
    if (debug) console.log('Method:', 'start')
    this.jmuxerWorker.postMessage({method: 'start'})
}

JmuxPlayer.prototype.start_playback = function(request, _mode, _isContinuous) {
    if (debug) console.log('Method:', 'start_playback')
    this.jmuxerWorker.postMessage({method: 'start_playback', args: [request, _mode, _isContinuous]})
}

JmuxPlayer.prototype.next_playback_segment = function(requestId, resetIndexBool) {
    if (debug) console.log('Method:', 'next_playback_segment')
    this.jmuxerWorker.postMessage({method: 'next_playback_segment', args: [requestId, resetIndexBool]})
}

JmuxPlayer.prototype.previous_playback_segment = function(requestId, resetIndexBool) {
    if (debug) console.log('Method:', 'previous_playback_segment')
    this.jmuxerWorker.postMessage({method: 'previous_playback_segment', args: [requestId, resetIndexBool]})
}

JmuxPlayer.prototype.skip_playback_segments = function(request) {
    if (debug) console.log('Method:', 'skip_playback_segments')
    this.jmuxerWorker.postMessage({method: 'skip_playback_segments', args: [request]})
}

JmuxPlayer.prototype.stop_playback = function() {
    if (debug) console.log('Method:', 'stop_playback')
    this.jmuxerWorker.postMessage({method: 'stop_playback'})
}

JmuxPlayer.prototype.getSegmentsFailed = function() {
    if (debug) console.log('Method:', 'getSegmentsFailed')
    this.jmuxerWorker.postMessage({method: 'getSegmentsFailed'})
}

JmuxPlayer.prototype.isLive = function() {
    if (debug) console.log('Method:', 'isLive')
    return this.workerVars.livePlay;
}

JmuxPlayer.prototype.isPaused = function() {
    if (debug) console.log('Method:', 'isPaused')
    return this.workerVars.isPaused;
}

JmuxPlayer.prototype.clearBuffers = function() {
    if (debug) console.log('Method:', 'clearBuffers')
    this.jmuxerWorker.postMessage({method: 'clearBuffers'})
}

JmuxPlayer.prototype.playback_change_request = function(requestId) {
    if (debug) console.log('Method:', 'playback_change_request')
    this.jmuxerWorker.postMessage({method: 'playback_change_request', args: [requestId]})
}

JmuxPlayer.prototype.startSdPlayback = function(request) {
    if (debug) console.log('Method:', 'startSdPlayback')
    this.jmuxerWorker.postMessage({method: 'startSdPlayback', args: [request]})
}

JmuxPlayer.prototype.setTime = function(time, pausePlayback) {
    if (debug) console.log('Method:', 'setTime')
    this.jmuxerWorker.postMessage({method: 'setTime', args: [time, undefined, pausePlayback]})
}

JmuxPlayer.prototype.setTimeVar = function(time) {
    if (debug) console.log('Method:', 'setTimeVar')
    this.jmuxerWorker.postMessage({method: 'setTimeVar', args: [time]})
}

JmuxPlayer.prototype.getTime = function() {
    if (debug) console.log('Method:', 'getTime', this.workerVars.currentTime)
    return this.workerVars.currentTime;
}

JmuxPlayer.prototype.getFrameBuffer = function() {
    if (debug) console.log('Method:', 'getFrameBuffer')
    return this.workerVars.currentFrameBufferTime;
}

JmuxPlayer.prototype.clearFrameBuffer = function() {
    if (debug) console.log('Method:', 'clearFrameBuffer')
    this.jmuxerWorker.postMessage({method: 'clearFrameBuffer'})
}

JmuxPlayer.prototype.pause = function() {
    if (debug) console.log('Method:', 'pause')
    this.jmuxerWorker.postMessage({method: 'pause'})
}

JmuxPlayer.prototype.play = function() {
    if (debug) console.log('Method:', 'play')
    this.jmuxerWorker.postMessage({method: 'play'})
}

JmuxPlayer.prototype.fastForward = function(speedValue) {
    if (debug) console.log('Method:', 'fastForward')
    this.jmuxerWorker.postMessage({method: 'fastForward', args: [speedValue]})
}

JmuxPlayer.prototype.stopFastForward = function() {
    if (debug) console.log('Method:', 'stopFastForward')
    this.jmuxerWorker.postMessage({method: 'stopFastForward'})
}

JmuxPlayer.prototype.rewind = function() {
    if (debug) console.log('Method:', 'rewind')
    this.jmuxerWorker.postMessage({method: 'rewind'})
}

JmuxPlayer.prototype.stopRewind = function() {
    if (debug) console.log('Method:', 'stopRewind')
    this.jmuxerWorker.postMessage({method: 'stopRewind'})
}

JmuxPlayer.prototype.adjustPlaybackSpeed = function(time) {
    if (debug) console.log('Method:', 'stopRewind')
    let video = document.getElementById(this.element)
    video.playbackRate = time;
}

JmuxPlayer.prototype.getJmuxer = function() {
    if (debug) console.log('Method:', 'getJmuxer')
    functionErr('getJmuxer')
    return this
}


export default JmuxPlayer;
