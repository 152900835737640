// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/router'
import decode from "jwt-decode";
import iss from '@/services/iss';
import vue_auth_image from '@/plugins/vue-auth-image';
//import axios from 'axios'
//axios.defaults.withCredentials = true;

import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(fas, far, fab);
Vue.component('fa-icon', FontAwesomeIcon)

// todo
// cssVars()

Vue.use(BootstrapVue)
Vue.use(vue_auth_image);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    template: '<App/>',
    components: {
        App
    }
})

/*
setInterval(function () {
    if (parseInt(localStorage.getItem("tokenExp")) < (Date.now() + 30000) && !window.location.hash.includes("teapoting") && !window.location.hash.includes("#/pages/login") && !window.location.hash.includes("#/passwordreset") && localStorage.getItem("started") === "false") {
        localStorage.setItem("started", "true");
        new iss.ISS().post('/auth/reauth', {reauthToken: localStorage.getItem("reauthToken")}).then((response) => {
            let token = decode(response.data.token);
            let oldToken = decode(response.data.oldToken);
            localStorage.setItem('tokenStatus', token.status);
            localStorage.setItem('mtopToken', response.data.token);
            localStorage.setItem('email', token.email);
            localStorage.setItem('type', token.type);
            localStorage.setItem('username', token.username);
            localStorage.setItem('id', token._id);
            localStorage.setItem('groups', token.groups);
            localStorage.setItem('firstname', token.firstname);
            localStorage.setItem('lastname', token.lastname);
            localStorage.setItem('oldToken', response.data.oldToken);
            localStorage.setItem('tokenExp', token.tokenExpiration);
            localStorage.setItem('reauthToken', token.reauthToken);
            localStorage.setItem('customFields', JSON.stringify(token.customFields));
            localStorage.setItem("started", "false");
        }, error => {
            if (error.response.status === 418 && !window.location.hash.includes("teapoting")) {
                window.location = "/" + window.location.hash + "?teapoting=true";
            } else if (error.response.status === 401) {
                window.location = "/#/pages/login";
            }
            localStorage.setItem("started", "false");
        });
    }
}, 500);*/
