import Vue from 'vue';
import Vuex from 'vuex';
import PubSub from 'pubsub-js';
import iss from '@/services/iss.js'

import authStore from "@/store/authStore";

Vue.use(Vuex);

let tenantStore = new Vuex.Store({

    state: {
        tenants: []
    },

    mutations: {
        updateTenants(state, tenants) {
            state.tenants = JSON.parse(JSON.stringify(tenants));
            PubSub.publish('updatetenants', tenants);
            tenants.forEach(tenant => {
                PubSub.publish('tenantupdate-' + tenant._id, tenant);
            })
        },
        addTenant(state, tenant) {
            state.tenants.push(Object.assign({}, tenant));
            PubSub.publish("updatetenants", state.tenants);
            PubSub.publish('tenantupdate-' + tenant._id, tenant);
        },
        postTenant(state, tenant) {
            state.constellations.push(tenant);
            PubSub.publish('updatetenants', state.tenants);
            PubSub.publish('tenantupdate-' + tenant._id, tenant);
        },
        putTenant(state, tenant, index) {
            state.tenants.splice(index, 1, tenant);
            PubSub.publish('updatetenants', state.tenants);
            PubSub.publish('tenantupdate-' + tenant._id, tenant);
        },
        deleteTenant(state, toDelete) {
            state.tenants = state.tenants.filter(_ => _._id !== toDelete);
            PubSub.publish("updatetenants", state.tenants);
        }
    },

    actions: {
        async getTenants({commit, state}) {
            if (state.tenants.length === 0) {
                let tenants = await authStore.dispatch('getSegmentGrants');
                commit('updateTenants', tenants);
            }
            return state.tenants;
        },
        async getTenant({commit, state}, toFind) {
            let tenant = state.tenants.find(site => site._id === toFind);
            if (tenant === undefined) {
                let tenant = await iss.getConstellation(toFind);
                commit('addTenant', tenant.data);
            }
            return state.tenants.find(_ => _._id === toFind);
        },
        async postTenant({commit, state}, data) {
            let tenant = await iss.createConstellation(data);
            commit('postTenant', tenant);
            return state.tenants.find(_ => _._id === tenant._id);
        },
        async putTenant({commit, state}, data) {
            let tenant = await iss.editConstellation(data);
            if (tenant === undefined) {
                let index = state.tenants.findIndex(_ => _._id === data._id);
                commit("putTenant", tenant, index)
            }
            return state.tenants.find(_ => _._id === tenant._id);
        },
        async deleteTenant({commit, state}, toDelete) {
            await iss.deleteConstellation(toDelete);
            commit('deleteTenant', toDelete)
        }
    },

    getters: {
        getTenants(state) {
            return state.tenants;
        },
    }
});

export default tenantStore;