<template>
    <div class="mToastContainer">
        <h4 class="toastTitle" v-if="title">{{ title }}</h4>
        <div class="toastText" v-if="text && typeof text === 'string'">{{ text }}</div>
        <div v-if="Array.isArray(text)" v-for="txt in text" :key="'toastText' + txt" class="toastText">
            {{ txt }}
        </div>
    </div>
</template>

<script>
export default {
    props: ["title", "text"],
};
</script>

<style>
.mToastContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.toastTitle {
    white-space: pre-wrap;
    width: 100%;
    font-weight: bold;
    margin: auto 0 auto 0;
}
.toastText {
    white-space: pre-wrap;
    width: 100%;
    transform: translateY(3px);
    margin: auto 0 auto 0;
}
</style>