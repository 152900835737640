import JmuxPlayer from "@/shared/jmuxer/jmuxplayer";
import SmartSuiteArchive from "./smartsuite_archive";
import SmartSuiteAnalytics from "./smartsuite_analytics";
import SmartSuiteSdCard from "./smartsuite_sdcard";
import SmartSuiteMedia from "./smartsuite_media";
import SmartSuiteDevicehub from "./smartsuite_devicehub";
import SmartSuiteEvent from "./smartsuite_events";
import SmartSuitePTZ from "./smartsuite_ptz";
import gammaStore from "@/store/gammaStore";
import deviceStore from "@/store/deviceStore";
import authStore from "@/store/authStore";

function SmartSuiteDevice(device) {
    this.device = JSON.parse(JSON.stringify(device));
    this.instances = {};
    this.archives = {};
    this.analytics = {};
    this.canvases = {};
    this.sdCards = {};
    this.events = {};
    this.PTZControllers = {};
    this.navbarShown = true;
    this.lastInFilter = false;
    this.zoomIncrement = .25;
    this.archive = false;
    this.miniCanvasScale = 4;   //how many times smaller the mini canvas is compared to the normal video window
}

SmartSuiteDevice.prototype.createPlayer = function (device, quality, sourceToken, dataChannel, archiveOnly) {
    this.instances[device.getDeviceId() + sourceToken + dataChannel] = new JmuxPlayer(device, 'gamma-video-' + device.getDeviceId() + sourceToken + dataChannel, quality, sourceToken, dataChannel, archiveOnly);
    return device.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.startPlayer = function (sourceId) {
    this.instances[sourceId].start();
}

SmartSuiteDevice.prototype.destroyPlayer = function (sourceId) {
    if (this.instances[sourceId]) {
        this.instances[sourceId].destroy();
        let element = document.getElementById('gamma-instance-'+sourceId);
        if (element) {
            element.remove();
        }
    }
}

SmartSuiteDevice.prototype.findQuality = function (streams, quality, device, sourceToken, dataChannel) {
    return find_quality(streams, quality, device, sourceToken, dataChannel, this.archive)
}

SmartSuiteDevice.prototype.startPlayback = function (sourceId, request, isContinuous) {
    //false for video only, at some point maybe true for both audio and video
    this.instances[sourceId].start_playback(request, false, isContinuous);
}

SmartSuiteDevice.prototype.nextPlaybackSegment = function (sourceId, requestId, resetIndexBool) {
    this.instances[sourceId].next_playback_segment(requestId, resetIndexBool);
}

SmartSuiteDevice.prototype.previousPlaybackSegment = function (sourceId, requestId, resetIndexBool) {
    this.instances[sourceId].previous_playback_segment(requestId, resetIndexBool);
}

SmartSuiteDevice.prototype.findStream = function (dataChannel) {
    return this.device.streams.find(stream => {
        return stream.dataChannel === dataChannel;
    });
}

SmartSuiteDevice.prototype.stopPlayback = function (sourceId) {
    if (this.instances[sourceId] !== undefined) {
        this.instances[sourceId].stop_playback();
    }
}

SmartSuiteDevice.prototype.getSegmentsFailed = function (sourceId) {
    this.instances[sourceId].getSegmentsFailed();
}

SmartSuiteDevice.prototype.isLive = function (sourceId) {
    return this.instances[sourceId].isLive();
}

SmartSuiteDevice.prototype.isPaused = function (sourceId) {
    return this.instances[sourceId].isPaused();
}

SmartSuiteDevice.prototype.isNavBarShown = function () {
    return this.navbarShown;
}

SmartSuiteDevice.prototype.clearBuffers = function (sourceId) {
    this.instances[sourceId].clearBuffers();
}

SmartSuiteDevice.prototype.getCurrentTime = function (sourceId) {
    return this.instances[sourceId].getCurrentTime();
}

SmartSuiteDevice.prototype.setArchive = function () {
    this.archive = true;
}

SmartSuiteDevice.prototype.setNavBarShown = function (boolean) {
    this.navbarShown = boolean;
}

SmartSuiteDevice.prototype.setLastInFilter = function (boolean) {
    this.lastInFilter = boolean;
}

SmartSuiteDevice.prototype.getExports = function (sourceId, callback) {
    if (this.archives[sourceId]) {
        this.archives[sourceId].getExports(callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.getArchiveDays = async function (sourceId, callback) {
    if (!this.archives[sourceId]) {
        let results = extractIdComponents(sourceId, this.getDeviceId());
        await this.createArchive(this, results.sourceToken, results.dataChannel);
    }
    this.archives[sourceId].getDays(callback2 => {
        callback(callback2);
    });
}

SmartSuiteDevice.prototype.getArchiveHours = async function (sourceId, selectedDay, callback) {
    if (!this.archives[sourceId]) {
        let results = extractIdComponents(sourceId, this.getDeviceId());
        await this.createArchive(this, results.sourceToken, results.dataChannel);
    }
    this.archives[sourceId].getHours(selectedDay, callback2 => {
        callback(callback2);
    })
}

SmartSuiteDevice.prototype.getArchiveSegments = async function (sourceId, dayCode, hourCode, callback) {
    if (!this.archives[sourceId]) {
        let results = extractIdComponents(sourceId, this.getDeviceId());
        await this.createArchive(this, results.sourceToken, results.dataChannel);
    }
    this.archives[sourceId].getSegments(dayCode, hourCode, callback2 => {
        callback(callback2);
    });
}

SmartSuiteDevice.prototype.streamArchiveRequest = function (sourceId, segments) {
    return this.archives[sourceId].streamArchiveRequest(segments);
}

SmartSuiteDevice.prototype.getStoreName = function (sourceId) {
    return this.archives[sourceId].getStoreName();
}

SmartSuiteDevice.prototype.getStorageServer = function () {
    return this.device.storageServer;
}

SmartSuiteDevice.prototype.createPTZhub = function (device, sourceToken, dataChannel) {
    if (this.PTZControllers[device.getDeviceId() + sourceToken + dataChannel] === undefined) {
        this.PTZControllers[device.getDeviceId() + sourceToken + dataChannel] = new SmartSuitePTZ(device);
    }
    return device.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.connectToPTZhub = async function (sourceId) {
    await this.PTZControllers[sourceId].connect();
}

SmartSuiteDevice.prototype.closePTZConnection = async function (sourceId) {
    if (this.PTZControllers[sourceId] !== undefined) {
        await this.PTZControllers[sourceId].close();
    }
}

SmartSuiteDevice.prototype.PTZZoom = function (sourceId, newPosition) {
    this.PTZControllers[sourceId].zoom(newPosition);
}

SmartSuiteDevice.prototype.PTZPanTilt = function (sourceId, x, y) {
    this.PTZControllers[sourceId].panTilt(x, y);
}

SmartSuiteDevice.prototype.PTZAbsoluteMove = function (sourceId, x, y) {
    this.PTZControllers[sourceId].absoluteMove(x, y);
}

SmartSuiteDevice.prototype.PTZSetPreset = function (sourceId, presetName) {
    this.PTZControllers[sourceId].setPreset(presetName);
}

SmartSuiteDevice.prototype.PTZRemovePreset = function (sourceId, presetName) {
    this.PTZControllers[sourceId].removePreset(presetName);
}

SmartSuiteDevice.prototype.PTZResetHome = function (sourceId) {
    this.PTZControllers[sourceId].resetHome();
}

SmartSuiteDevice.prototype.PTZGoToHome = function (sourceId) {
    this.PTZControllers[sourceId].goToHome();
}

SmartSuiteDevice.prototype.PTZClickMove = function (sourceId, x, y, space) {
    this.PTZControllers[sourceId].clickMove(x, y, space);
}

SmartSuiteDevice.prototype.setPTZStatusListener = function (sourceId, callback) {
    this.PTZControllers[sourceId].setPTZStatusListener(event => {
        callback(event);
    });
}

SmartSuiteDevice.prototype.addPTZSourceListener = function (sourceId) {
    this.PTZControllers[sourceId].addSourceListener();
}

SmartSuiteDevice.prototype.getPTZStatus = function (sourceId) {
    return this.PTZControllers[sourceId].getPTZStatus();
}

SmartSuiteDevice.prototype.getPTZZoomPosition = function (sourceId) {
    return this.PTZControllers[sourceId].getZoomPosition();
}

SmartSuiteDevice.prototype.getPTZPresets = function (sourceId) {
    return this.PTZControllers[sourceId].getPresets();
}

SmartSuiteDevice.prototype.createEventHub = function (device, sourceToken, dataChannel) {
    if (this.events[device.getDeviceId() + sourceToken + dataChannel] === undefined) {
        this.events[device.getDeviceId() + sourceToken + dataChannel] = new SmartSuiteEvent(device, sourceToken, dataChannel);
    }
    return device.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.connectToEvents = async function (eventId) {
    await this.events[eventId].connect();
}

SmartSuiteDevice.prototype.closeEventConnection = async function (eventId) {
    if (this.events[eventId] !== undefined) {
        await this.events[eventId].close();
    }
}

SmartSuiteDevice.prototype.setEventHubListener = function (eventId, callback) {
    if (this.events[eventId] !== undefined) {
        this.events[eventId].eventListener(callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.getMotionEvents = async function (eventId) {
    if (this.events[eventId] !== undefined) {
        return await this.events[eventId].getMotionEvents();
    }
}

SmartSuiteDevice.prototype.initializeDevice = async function (eventId, serialNumber) {
    if (this.events[eventId] !== undefined) {
        return await this.events[eventId].initializeDevice(serialNumber);
    }
}

SmartSuiteDevice.prototype.getDeviceEventsByType = async function (eventId, eventType, startTime, endTime, callback) {
    if (this.events[eventId] !== undefined) {
        this.events[eventId].getDeviceEventsByType(eventType, startTime, endTime, callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.checkForArchive = function (sourceId) {
    return this.archives[sourceId] !== undefined;
}

SmartSuiteDevice.prototype.createArchive = function (ssDevice, sourceToken, dataChannel) {
    if (this.archives[ssDevice.getDeviceId() + sourceToken + dataChannel] === undefined) {
        this.archives[ssDevice.getDeviceId() + sourceToken + dataChannel] = new SmartSuiteArchive(ssDevice, sourceToken, dataChannel);
    }
    return ssDevice.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.connectToArchive = async function (sourceId) {
    if (this.archives[sourceId] !== undefined) {
        await this.archives[sourceId].connect();
    } else {
        let deviceComponents = extractIdComponents(sourceId, this.getDeviceId());
        await this.createArchive(this, deviceComponents.sourceToken, deviceComponents.dataChannel);
        await this.connectToArchive(sourceId);
    }
}

SmartSuiteDevice.prototype.closeArchiveConnection = function (sourceId) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].close();
    }
}

SmartSuiteDevice.prototype.setExportUpdateListener = function (sourceId, callback) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].exportUpdateListener(callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.setExportUpdatesListener = function (sourceId, callback) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].exportUpdatesListener(callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.deleteArchiveExport = function (sourceId, archiveExport) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].deleteArchiveExport(archiveExport);
    }
}

SmartSuiteDevice.prototype.createAnalytics = function (device, sourceToken, dataChannel) {
    if (this.analytics[device.getDeviceId() + sourceToken + dataChannel] === undefined) {
        this.analytics[device.getDeviceId() + sourceToken + dataChannel] = new SmartSuiteAnalytics(device, sourceToken);
    }
    return device.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.connectToAnalytics = async function (analyticsId) {
    await this.analytics[analyticsId].connect(analyticsId);
}

SmartSuiteDevice.prototype.closeAnalyticsConnection = function (analyticsId) {
    if (this.analytics[analyticsId] !== undefined) {
        this.analytics[analyticsId].close();
    }
}

SmartSuiteDevice.prototype.findAnalyticsSegments = function (analyticsId, segments, targetClass) {
    this.analytics[analyticsId].findAnalyticsSegments(segments, targetClass);
}

SmartSuiteDevice.prototype.cancelSearchJob = function (analyticsId, jobId) {
    if (this.analytics[analyticsId]) {
        this.analytics[analyticsId].stopAnalyticsJob(jobId);
    }
}

SmartSuiteDevice.prototype.analyticsResponseListener = async function (analyticsId, analyticsCallback) {
    await this.analytics[analyticsId].analyticsResponseListener(analyticsCallback);
}

SmartSuiteDevice.prototype.startSdPlayback = function (sourceId, request) {
    this.instances[sourceId].startSdPlayback(request)
}

SmartSuiteDevice.prototype.createSdCard = function (device, sourceToken, dataChannel) {
    if (this.sdCards[device.getDeviceId() + sourceToken + dataChannel] === undefined) {
        this.sdCards[device.getDeviceId() + sourceToken + dataChannel] = new SmartSuiteSdCard(device, sourceToken, dataChannel);
    }
    return device.getDeviceId() + sourceToken + dataChannel;
}

SmartSuiteDevice.prototype.streamSdCardRequest = async function(sdCardId, startTime, endTime, record, callback) {
    if (this.sdCards[sdCardId] !== undefined) {
        this.sdCards[sdCardId].streamSdCardRequest(startTime, endTime, record, callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.connectToSdCard = async function (sdCardId) {
    await this.sdCards[sdCardId].connect(sdCardId);
}

SmartSuiteDevice.prototype.closeSdCardConnection = function (sdCardId) {
    if (this.sdCards[sdCardId] !== undefined) {
        this.sdCards[sdCardId].close();
    }
}

SmartSuiteDevice.prototype.getStreamServer = function (streamTypes, callback) {
    if (this.archive === true) {
        return this.device.streamServer;
    }
    streamTypes.forEach(streamType => {
        let sdCardStream = this.device.streams.find(stream => {
            return stream.streamType === streamType
        })
        if (sdCardStream) {
            callback(sdCardStream.streamServer);
        }
    })
}

SmartSuiteDevice.prototype.setSdCardRecordingListener = async function (sdCardId, sdCardCallback) {
    await this.sdCards[sdCardId].sdCardRecordingsListener(sdCardCallback);
}

SmartSuiteDevice.prototype.getSdCardRecordings = function (sdCardId) {
    if (this.sdCards[sdCardId] !== undefined) {
        this.sdCards[sdCardId].getSdCardRecordings(sdCardId);
    }
}

SmartSuiteDevice.prototype.hasSdCard = function () {
    return this.device.hasSdCard;
}

SmartSuiteDevice.prototype.exportArchiveRequest = function (sourceId, segments, exportName, callback) {
    this.archives[sourceId].exportArchiveRequest(segments, exportName, callback2 => {
        callback(callback2);
    })
}

SmartSuiteDevice.prototype.exportSdCardRequest = function (sdCardId, startTime, endTime, record, exportName, callback) {
    this.sdCards[sdCardId].exportSdCardRequest(startTime, endTime, record, exportName, callback2 => {
        callback(callback2);
    })
}


SmartSuiteDevice.prototype.isAudioStream = async function (sourceId) {
    return await this.archives[sourceId].isAudioStream();
}

SmartSuiteDevice.prototype.isAudioDevice = function () {
    if(this.device.streams !== undefined) {
        return this.device.streams.find(_ => _.streamType === SmartSuiteArchive.streamTypes.Audio) !== undefined;
    }
    return false
}

SmartSuiteDevice.prototype.getAudioStream = function () {
    return this.device.streams.find(_ => _.streamType === SmartSuiteArchive.streamTypes.Audio);
}

SmartSuiteDevice.prototype.setStreamType = function (sourceId, audioVideo) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].setStreamType(audioVideo);
    }
}

SmartSuiteDevice.prototype.createInstance = function (sourceToken, dataChannel) {
    return create_instance(this, sourceToken, dataChannel);
}

SmartSuiteDevice.prototype.getInstance = function (sourceId) {
    return this.instances[sourceId];
}

SmartSuiteDevice.prototype.getDevice = function () {
    return this.device;
}

SmartSuiteDevice.prototype.getStreamQualities = function (sourceToken, dataChannel) {
    let qualities = this.device.streams.reduce((filtered, stream) => {
        if (this.isPanoramic() === true) {
            if (stream.streamType === SmartSuiteArchive.streamTypes.Video && stream.sourceToken === sourceToken && stream.dataChannel === dataChannel) {
                let value = {value: stream.streamQuality, text: get_quality_name(stream.streamQuality)};
                filtered.push(value);
            }
        } else if (stream.streamType === SmartSuiteArchive.streamTypes.Video && stream.sourceToken === sourceToken) {
            let value = {value: stream.streamQuality, text: get_quality_name(stream.streamQuality)};
            filtered.push(value);
        }
        return filtered;
    }, []);
    return qualities.slice().sort((a, b) => a.value - b.value);
}

SmartSuiteDevice.prototype.getTenantId = function () {
    return this.device.tenantId;
}

SmartSuiteDevice.prototype.getDeviceName = function () {
    return this.device.friendlyName;
}

SmartSuiteDevice.prototype.getDeviceStreamName = function (dataChannel, sourceToken) {
    if (dataChannel !== undefined) {
        let result = this.device.streams.find(stream => {
            return stream.dataChannel === dataChannel;
        });
        return result !== undefined ? result.friendlyName : null;
    } else if (sourceToken !== undefined) {
        let result = this.device.streams.find(stream => {
            return (stream.sourceToken === sourceToken && stream.streamType === SmartSuiteArchive.streamTypes.Video);
        });
        return result !== undefined ? result.friendlyName : null;
    }
    return null;
}

SmartSuiteDevice.prototype.getDeviceType = function () {
    return this.device.devicetype;
}

SmartSuiteDevice.prototype.getDeviceId = function () {
    return this.device._id;
}

SmartSuiteDevice.prototype.reboot = async function (device) {
    let devicehub = new SmartSuiteDevicehub(device);
    await devicehub.connect();
    await devicehub.rebootDevice();
}

SmartSuiteDevice.prototype.getNetworkConfig = function () {
    if (this.getAutoDNS() && this.getAutoIp()) {
        return 0;
    } else if (!this.getAutoDNS() && this.getAutoIp()) {
        return 1;
    } else {
        return 2;
    }
}

SmartSuiteDevice.prototype.getNetworkConfigText = function () {
    switch (this.getNetworkConfig()) {
        case 0:
            return 'Automatic IP (DHCP) and DNS (DHCP)';
        case 1:
            return 'Automatic IP (DHCP) and manual DNS';
        default:
            return 'Manual IP and manual DNS';
    }
}

SmartSuiteDevice.prototype.getMACAddress = function () {
    return this.device.macAddress;
}

SmartSuiteDevice.prototype.getDeviceModel = function () {
    return this.device.model;
}

SmartSuiteDevice.prototype.getCommandChannel = function () {
    return this.device.commandChannel;
}

SmartSuiteDevice.prototype.getDeviceMake = function () {
    return this.device.manufacturer;
}

SmartSuiteDevice.prototype.getAutoDNS = function () {
    return this.device.networkConfiguration.hostnameConfig.fromDHCP;
}

SmartSuiteDevice.prototype.getAutoIp = function () {
    return this.device.networkConfiguration.interfaces[0].iPv4.config.dhcp;
}

SmartSuiteDevice.prototype.getIpAddress = function () {
    if (this.device.networkConfiguration !== null && this.device.networkConfiguration.interfaces[0].iPv4.config.dhcp === true) {
        return this.device.networkConfiguration.interfaces[0].iPv4.config.fromDHCP.address;
    } else if (this.device.networkConfiguration !== null && this.device.networkConfiguration.interfaces[0].iPv4.config.dhcp === false) {
        return this.device.networkConfiguration.interfaces[0].iPv4.config.manual[0].address;
    } else {
        return this.device.ipAddress;
    }
}

SmartSuiteDevice.prototype.getDefaultRouter = function () {
    //return this.device.networkConfiguration.gatewayConfig.iPv4Address[0];  //TO-DO
    return null;
}

SmartSuiteDevice.prototype.getMask = function () {
    if (this.device.networkConfiguration.interfaces[0].iPv4.config.dhcp === true) {
        return this.device.networkConfiguration.interfaces[0].iPv4.config.fromDHCP.prefixLength.toString();
    } else if (this.device.networkConfiguration.interfaces[0].iPv4.config.dhcp === false) {
        return this.device.networkConfiguration.interfaces[0].iPv4.config.manual[0].prefixLength.toString();
    } else {
        return "N/A";
    }
}

SmartSuiteDevice.prototype.getAutoNTP = function () {
    return this.device.networkConfiguration.ntpConfig.fromDHCP;
}

SmartSuiteDevice.prototype.getManualNTP = function () {
    return this.device.networkConfiguration.systemDateAndTime.dateTimeType !== 0;
}

SmartSuiteDevice.prototype.isManualNTP = function () {
    return this.device.networkConfiguration.ntpConfig.ntpManual;
}

SmartSuiteDevice.prototype.getNTP = function (index) {
    if (this.isManualNTP() && this.device.networkConfiguration.ntpConfig.ntpManual[index]) {
        return this.device.networkConfiguration.ntpConfig.ntpManual[index].dnSname;
    }
    return null;
}

SmartSuiteDevice.prototype.getNTPSettingText = function () {
    switch (this.getNTPSetting()) {
        case 0:
            return 'Automatic date and time (NTP server using DHCP)';
        case 1:
            return 'Automatic date and time (manual NTP server)';
        default:
            return 'Custom date and time';
    }
}

SmartSuiteDevice.prototype.getNTPSetting = function () {
    if (this.getAutoNTP() && this.getManualNTP()) {
        return 0;
    } else if (!this.getAutoNTP() && this.getManualNTP()) {
        return 1;
    } else {
        return 2;
    }
}

SmartSuiteDevice.prototype.getDeviceDate = function () {
    if (this.device.networkConfiguration.systemDateAndTime.localDateTime !== null) {
        let dateLocation = this.device.networkConfiguration.systemDateAndTime.localDateTime.date;
        let timeLocation = this.device.networkConfiguration.systemDateAndTime.localDateTime.time;
        return new Date(dateLocation.year, dateLocation.month - 1, dateLocation.day, timeLocation.hour, timeLocation.minute, timeLocation.second);
    } else {
        let dateLocation = this.device.networkConfiguration.systemDateAndTime.utcDateTime.date;
        let timeLocation = this.device.networkConfiguration.systemDateAndTime.utcDateTime.time;
        return new Date(dateLocation.year, dateLocation.month - 1, dateLocation.day, timeLocation.hour, timeLocation.minute, timeLocation.second);
    }
}

SmartSuiteDevice.prototype.getDeviceTime = function () {
    return this.getDeviceDate();
}

SmartSuiteDevice.prototype.getTimeZone = function () {
    return this.device.networkConfiguration.systemDateAndTime.timeZone.tz;
}

SmartSuiteDevice.prototype.getDomainNames = function () {
    return this.device.networkConfiguration.deviceDNS.searchDomain;
}

SmartSuiteDevice.prototype.isManualDNS = function () {
    return !this.device.networkConfiguration.deviceDNS.fromDHCP;
}

SmartSuiteDevice.prototype.getDNS = function (index) {
    if (this.isManualDNS() && this.device.networkConfiguration.deviceDNS.dnsManual && this.device.networkConfiguration.deviceDNS.dnsManual[index]) {
        return this.device.networkConfiguration.deviceDNS.dnsManual[index].iPv4Address;
    } else if (!this.isManualDNS() && this.device.networkConfiguration.deviceDNS.dnsFromDHCP && this.device.networkConfiguration.deviceDNS.dnsFromDHCP[index]) {
        return this.device.networkConfiguration.deviceDNS.dnsFromDHCP[index].iPv4Address;
    }
    return null;
}

SmartSuiteDevice.prototype.getLongitude = function () {
    return parseFloat(this.device.longitude) || 0;
}

SmartSuiteDevice.prototype.getLatitude = function () {
    return parseFloat(this.device.latitude) || 0;
}

SmartSuiteDevice.prototype.updateDevice = function (device) {
    //we did a special filter on the streams when we first created the device we do not want that changed
    let oldStreams = this.device.streams;
    this.device = Object.assign(this.device, device);
    if (oldStreams !== null && oldStreams.length > 0) {
        this.addStreams(oldStreams);
    }
}

SmartSuiteDevice.prototype.modifyDevice = async function (device) {
    let ssMedia = new SmartSuiteMedia(device);
    await ssMedia.connect();
    await ssMedia.updateDevice();
    return this;
}

SmartSuiteDevice.prototype.seek = function (sourceId, time) {
    this.instances[sourceId].seek(time);
}

SmartSuiteDevice.prototype.setInstanceTime = function (sourceId, time, pausePlayback) {
    this.instances[sourceId].setTime(time, pausePlayback);
}

SmartSuiteDevice.prototype.setTimeVar = function (sourceId, time) {
    this.instances[sourceId].setTimeVar(time)
}

SmartSuiteDevice.prototype.getTime = function (sourceId) {
    return this.instances[sourceId].getTime();
}

SmartSuiteDevice.prototype.getFrameBuffer = function (sourceId) {
    return this.instances[sourceId].getFrameBuffer();
}

SmartSuiteDevice.prototype.clearFrameBuffer = function (sourceId) {
    this.instances[sourceId].clearFrameBuffer();
}

SmartSuiteDevice.prototype.pause = function (sourceId) {
    this.instances[sourceId].pause();
}

SmartSuiteDevice.prototype.play = function (sourceId) {
    this.instances[sourceId].play();
}

SmartSuiteDevice.prototype.fastForward = function (sourceId, speedValue) {
    this.instances[sourceId].fastForward(speedValue);
}

SmartSuiteDevice.prototype.stopFastForward = function (sourceId) {
    this.instances[sourceId].stopFastForward();
}

SmartSuiteDevice.prototype.rewind = function (sourceId) {
    this.instances[sourceId].rewind();
}

SmartSuiteDevice.prototype.stopRewind = function (sourceId) {
    this.instances[sourceId].stopRewind();
}

SmartSuiteDevice.prototype.adjustPlaybackSpeed = function (sourceId, speed) {
    this.instances[sourceId].adjustPlaybackSpeed(speed);
}

SmartSuiteDevice.prototype.getJmuxer = function (sourceId) {
    return this.instances[sourceId].getJmuxer();
}

SmartSuiteDevice.prototype.isRecording = function () {
    return this.getStreams().find(_ => _.isRunning === true && (_.streamType === SmartSuiteArchive.streamTypes.VideoArchive || _.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive)) !== undefined;
}

SmartSuiteDevice.prototype.isPanoramic = function () {
    if (this.device.model !== undefined) {
        return this.device.model.includes('4308');
    }
    return false;
}

SmartSuiteDevice.prototype.getDetectionStatus = function () {
    if (this.device.detectionStatus !== -1) {
        if (this.device.detectedAt * 1000 > Date.now() - (60 * 1000)) {
            //return live
            return 0;
        } else if (this.device.detectedAt * 1000 > Date.now() - (120 * 1000)) {
            //return late
            return 1;
        } else {
            return 2;
        }
    } else {
        return -1;
    }
}

SmartSuiteDevice.prototype.getStatusText = function () {
    switch (this.getDetectionStatus()) {
        case 0:
            return 'Online';
        case 1:
            return 'Late';
        case 2:
            return 'Offline';
        case -1:
            return 'Login Failure';
        default:
            return 'Unknown Status';
    }
}

SmartSuiteDevice.prototype.getMultiSensor = function () {
    return this.device.multisensor;
}

SmartSuiteDevice.prototype.getUserIds = function (sourceToken, userName) {
    let result = [userName];
    if (this.device.streams && Array.isArray(this.device.streams)) {
        this.device.streams.forEach(stream => {
            if (stream.userIds && Array.isArray(stream.userIds) && stream.userIds.length > 0
                && (stream.sourceToken === sourceToken || sourceToken === undefined)) {
                result = [...result, ...stream.userIds];
            }
        });
    }
    return [...new Set(result)].map(_ => _ === userName ? _ + " (you)" : _);
}

SmartSuiteDevice.prototype.getStreams = function () {
    return this.device.streams || [];
}

SmartSuiteDevice.prototype.getAllSourceQualities = function (sourceToken) {
    return this.device.streams.filter(stream => {
        return stream.sourceToken === sourceToken && stream.streamType === SmartSuiteArchive.streamTypes.Video;
    });
}

SmartSuiteDevice.prototype.getMultiSensorStreams = function (quality) {
    let sources = [];
    if (this.device.streams) {
        //this groups the stream by their sourcetoken and filters out non-livestreams
        let groupedStreams = groupBy(this.device.streams.filter(stream => stream.streamType === SmartSuiteArchive.streamTypes.Video), 'sourceToken');
        //this finds the desired quality || the highest quality for each available view
        Object.values(groupedStreams).forEach(group => {
            let selectedStream = group.find(stream => stream.streamQuality === quality) ||
                group.reduce((max, stream) => (stream.streamQuality > max.streamQuality ? stream : max), group[0]);
            sources.push(selectedStream);
        });
    }
    return sources;
}

SmartSuiteDevice.prototype.getSingleSensorStreams = function (quality, isPanoramic) {
    let sources = [];
    if (isPanoramic && this.device && this.device.streams) {
        //this groups the stream by their datachannel and filters out non-livestreams, since atm, dataChannel is the same for all qualities of a view on panoramic cams
        let groupedStreams = groupBy(this.device.streams.filter(stream => stream.streamType === SmartSuiteArchive.streamTypes.Video), 'dataChannel');
        //this finds the desired quality || the highest quality for each available view
        Object.values(groupedStreams).forEach(group => {
            let selectedStream = group.find(stream => stream.streamQuality === quality) ||
                group.reduce((max, stream) => (stream.streamQuality > max.streamQuality ? stream : max), group[0]);
            sources.push(selectedStream);
        });
    } else if (this.device && this.device.streams) {
        let liveStreams = this.device.streams.filter(stream => stream.streamType !== SmartSuiteArchive.streamTypes.Video);
        let selectedStream = liveStreams.find(stream => stream.streamQuality === quality) ||
            liveStreams.reduce((max, stream2) => (stream2.streamQuality > max.streamQuality ? stream2 : max), liveStreams[0]);
        sources.push(selectedStream);
    }
    return sources;
}

SmartSuiteDevice.prototype.getArchiveStreams = function () {
    let sources = [];
    if (this.device && this.getStreams()) {
        this.getStreams().forEach(stream => {
            if (stream.streamType === SmartSuiteArchive.streamTypes.VideoArchive || stream.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive || stream.streamType === SmartSuiteArchive.streamTypes.AvSync) {
                sources.push(stream);
            }
        });
    }
    return sources;
}

SmartSuiteDevice.prototype.getArchiveStream = function (sourceToken) {
    return this.device.streams.find(stream => {
        return stream.sourceToken === sourceToken &&
              (stream.streamType === SmartSuiteArchive.streamTypes.VideoArchive ||
               stream.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive);
    });
}

SmartSuiteDevice.prototype.getArchiveStreamDataChannel = function (sourceToken) {
    let result = this.device.streams.find(stream => {
        return stream.sourceToken === sourceToken &&
            (stream.streamType === SmartSuiteArchive.streamTypes.VideoArchive ||
                stream.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive);
    });
    return result !== undefined ? result.dataChannel : null;
}

SmartSuiteDevice.prototype.getRetention = function (sourceId, callback) {
    if (this.archives[sourceId] !== undefined) {
        this.archives[sourceId].getRetention(callback2 => {
            callback(callback2);
        });
    }
}

SmartSuiteDevice.prototype.getDeviceQuality = function (dataChannel) {
    if (dataChannel.includes('low')) {
        return 0;
    } else if (dataChannel.includes('medium')) {
        return 1;
    } else if (dataChannel.includes('high')) {
        return 2;
    }
    return undefined;
}

SmartSuiteDevice.prototype.getValidStream = function (sourceToken) {
    if (sourceToken) {
        let stream = this.device.streams.find(_ => _.streamQuality === 2 && _.streamType === SmartSuiteArchive.streamTypes.Video && _.sourceToken === sourceToken);
        if (stream !== undefined) {
            return stream;
        } else {
            //try to find medium quality stream
            stream = this.device.streams.find(_ => _.streamQuality === 1 && _.streamType === SmartSuiteArchive.streamTypes.Video && _.sourceToken === sourceToken);
            if (stream !== undefined) {
                return stream;
            } else {
                //try to find low quality stream
                stream = this.device.streams.find(_ => _.streamQuality === 0 && _.streamType === SmartSuiteArchive.streamTypes.Video && _.sourceToken === sourceToken);
                return stream;
            }
        }
    } else {
        //try to find a high quality stream
        let stream = this.device.streams.find(_ => _.streamQuality === 2 && _.streamType === SmartSuiteArchive.streamTypes.Video);
        if (stream !== undefined) {
            return stream;
        } else {
            //try to find medium quality stream
            stream = this.device.streams.find(_ => _.streamQuality === 1 && _.streamType === SmartSuiteArchive.streamTypes.Video);
            if (stream !== undefined) {
                return stream;
            } else {
                //try to find low quality stream
                stream = this.device.streams.find(_ => _.streamQuality === 0 && _.streamType === SmartSuiteArchive.streamTypes.Video);
                return stream;
            }
        }
    }
}

SmartSuiteDevice.prototype.getUrl = function () {
    return this.device.url;
}

SmartSuiteDevice.prototype.returnDataChannel = function () {
    return this.device.dataChannel;
}

SmartSuiteDevice.prototype.getSourceToken = function () {
    //this function is only used when a layout needs to be displayed and the message services are not open yet
    return this.device.sourceToken;
}

SmartSuiteDevice.prototype.getHubName = function () {
    return this.device.hubName;
}

SmartSuiteDevice.prototype.getEdgeService = function () {
    return this.device.edgeService;
}

SmartSuiteDevice.prototype.getFirstDataChannel = function () {
    return this.getValidStream().dataChannel;
}

SmartSuiteDevice.prototype.getDataChannel = function (sourceToken, quality) {
    sourceToken = sourceToken === undefined ? '0' : sourceToken;
    quality = quality === undefined ? 2 : quality;
    let result = this.device.streams.find(stream => stream.sourceToken === sourceToken && stream.streamQuality === quality && stream.streamType === SmartSuiteArchive.streamTypes.Video) ||
        this.device.streams.find(stream => stream.sourceToken === sourceToken && stream.streamType === SmartSuiteArchive.streamTypes.Video);
    return result.dataChannel;
}

SmartSuiteDevice.prototype.getFirstSourceToken = function () {
    return this.getValidStream().sourceToken;
}

SmartSuiteDevice.prototype.addStreams = function (streams) {
    streams.forEach(stream => {
        let result = this.device.streams.find(existingStream => {
            return existingStream.dataChannel === stream.dataChannel;
        });
        if (result === undefined) {
            this.device.streams.push(stream);
        }
    });
}

SmartSuiteDevice.prototype.getSerialNumber = function () {
    return this.device.serialNumber || this.device._id;
}

SmartSuiteDevice.prototype.findAVSyncArchive = function () {
    if (this.archive === true) {
        return null;
    }
    return this.device.streams.find(_ => _.streamType === SmartSuiteArchive.streamTypes.AvSync) || null
}

SmartSuiteDevice.prototype.startArchive = async function (sourceToken, dataChannel) {
    let gamma = await gammaStore.dispatch('getMediahub', this.getTenantId());
    let userName = await authStore.dispatch('getUserName');
    let command_message = {
        CommandRequest: 4,
        DeviceSerialNumber: this.getSerialNumber(),
        DataChannel: this.getStreams().find(_ => _.sourceToken === sourceToken && _.dataChannel === dataChannel && (_.streamType === SmartSuiteArchive.streamTypes.VideoArchive || _.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive || _.streamType === SmartSuiteArchive.streamTypes.AvSync)).dataChannel,
        HubCommandChannel: this.getHubName() + "_command",
        UserId: userName,
    };
    gamma.sendMessage('sendusermessage', command_message);
}

SmartSuiteDevice.prototype.stopArchive = async function (sourceToken, dataChannel) {
    let gamma = await gammaStore.dispatch('getMediahub', this.getTenantId());
    let userName = await authStore.dispatch('getUserName');
    let command_message = {
        CommandRequest: 5,
        DeviceSerialNumber: this.getSerialNumber(),
        DataChannel: this.getStreams().find(_ => _.sourceToken === sourceToken && _.dataChannel === dataChannel && (_.streamType === SmartSuiteArchive.streamTypes.VideoArchive || _.streamType === SmartSuiteArchive.streamTypes.AudioVideoArchive || _.streamType === SmartSuiteArchive.streamTypes.AvSync)).dataChannel,
        HubCommandChannel: this.getHubName() + "_command",
        UserId: userName,
    };
    gamma.sendMessage('sendusermessage', command_message);
}

SmartSuiteDevice.prototype.toggleMotionRecording = async function (dataChannel) {
    let gamma = await gammaStore.dispatch('getMediahub', this.getTenantId());
    let userName = await authStore.dispatch('getUserName');
    let command_message = {
        CommandRequest: 10,
        DeviceSerialNumber: this.getSerialNumber(),
        DataChannel: dataChannel,
        HubCommandChannel: this.getHubName() + "_command",
        UserId: userName
    };
    gamma.sendMessage('sendusermessage', command_message);
}

SmartSuiteDevice.prototype.getDeviceErrors = async function () {
    let errors = await deviceStore.dispatch('getDeviceErrors', this.getTenantId());
    return errors.filter(_ => _.deviceIP === this.getIpAddress());
}

SmartSuiteDevice.prototype.getKeyFrameImages = async function (sourceId, requests, callback) {
    await this.archives[sourceId].getKeyFrameImages(requests, callback2 => {
        callback(callback2);
    });
}

SmartSuiteDevice.prototype.findArchivedSegments = async function (deviceId, startTimeDate, endTimeDate, callback) {
    let dayCodes = [], requestIdInfo = [];
    let startTime = new Date(startTimeDate).valueOf();
    let endTime = new Date(endTimeDate).valueOf();
    //get all the day codes for the archived footage
    let endDayCode = Math.floor(new Date(endTime).valueOf()/86400000);
    let startDayCode = Math.floor(new Date(startTime).valueOf()/86400000);
    for (let i = startDayCode; i !== endDayCode+1; i++) {
        dayCodes.push(i);
    }
    //get all the hour codes inside objects for archived footage and the one before
    let startHourCode = new Date(new Date(startTime).valueOf()-3600*1000).getUTCHours();
    let endHourCode = new Date(endTime).getUTCHours();
    let dayIncrement = dayCodes.length - 1;
    //Since we grab the hour before, but not the day before this is to prevent it breaking if we needed the day before also.
    if (startHourCode === 23) {
        requestIdInfo.push({dayCode: dayCodes[0] - 1, hourCode: 23});
        startHourCode = 0;
    }
    //generate the requests to get segments
    for (let j = startHourCode; j < endHourCode+(dayIncrement*24)+1; j++) {
        if (j > 23) {
            requestIdInfo.push({dayCode: dayCodes[Math.floor(j / 24)], hourCode: j % 24});
        } else {
            requestIdInfo.push({dayCode: dayCodes[0], hourCode: j});
        }
    }
    //add the available segments to each hour and create the request ids
    let count = 0, count2 = 0, usedSegments = [];
    let storeName = await this.getStoreName(deviceId);
    for (let i = 0; i < requestIdInfo.length; i++) {
        await this.getArchiveSegments(deviceId, requestIdInfo[i].dayCode, requestIdInfo[i].hourCode, segments => {
            if (segments !== undefined) {
                if (segments.length === 0) {
                    segments.push('0');
                }
                segments.forEach(async segment => {
                    if (segment > startTime - 19940 && segment < endTime) {
                        usedSegments.push({
                            segment: parseInt(segment),
                            dayCode: requestIdInfo[i].dayCode,
                            hourCode: requestIdInfo[i].hourCode,
                            endTime: endTime,
                            storeName: storeName
                        });
                    }
                    count++;
                    if (count === segments.length) {
                        count = 0;
                        count2++;
                        if (count2 === requestIdInfo.length) {
                            callback(usedSegments.sort((a, b) => {
                                if (parseInt(a.segment) < parseInt(b.segment))
                                    return -1;
                                if (parseInt(a.segment) > parseInt(b.segment))
                                    return 1;
                                return 0;
                            }))
                        }
                    }
                });
            } else {
                console.error('error getting archive segment, retrying in 5 seconds');
                setTimeout(() => {
                    this.findArchivedSegments(deviceId, startTimeDate, endTimeDate, callback);
                }, 5000)
            }
        });
    }
}

SmartSuiteDevice.prototype.getClickPTZ = function () {
    if (this.device.clickPTZ === undefined) {
        this.device.clickPTZ = false;
    }
    return this.device.clickPTZ;
}

SmartSuiteDevice.prototype.setClickPTZ = function (boolean) {
    this.device.clickPTZ = boolean;
}

SmartSuiteDevice.prototype.supportPtz = function () {
    return this.device.supportPtz;
}

SmartSuiteDevice.prototype.supportEvents = function () {
    return this.device.supportEvents;
}

SmartSuiteDevice.prototype.changeDraggable = async function (sourceId, boolean) {
    if (!this.canvases[sourceId]) {
        await this.createCanvas(sourceId, boolean);
    } else {
        this.canvases[sourceId].draggable = boolean;
    }
}

SmartSuiteDevice.prototype.changeShowMiniCanvas = function (sourceId, boolean) {
    this.canvases[sourceId].showMiniCanvas = boolean;
}

SmartSuiteDevice.prototype.isZoomedIn = function (sourceId) {
    return this.getCanvasZoom(sourceId) >= 1;
}

SmartSuiteDevice.prototype.getCanvasLoadingStatus = function (sourceId) {
    return this.canvases[sourceId].loading;
}

SmartSuiteDevice.prototype.setCanvasLoadingStatus = function (sourceId, loadingStatusBoolean) {
    this.canvases[sourceId].loading = loadingStatusBoolean;
}

SmartSuiteDevice.prototype.setCanvasErrorMessage = function (sourceId, errorMessage, errorMessage2) {
    if (this.canvases[sourceId]) {
        this.canvases[sourceId].errorMessage = errorMessage;
        if (errorMessage2 !== undefined) {
            this.canvases[sourceId].errorMessage2 = errorMessage2;
        } else {
            this.canvases[sourceId].errorMessage2 = '';
        }
    }
}

SmartSuiteDevice.prototype.getCanvasWidth = function (sourceId) {
    return this.canvases[sourceId].width;
}

SmartSuiteDevice.prototype.getCanvasHeight = function (sourceId) {
    return this.canvases[sourceId].height;
}

SmartSuiteDevice.prototype.getCanvasZoom = function (sourceId) {
    if (this.canvases[sourceId] !== undefined) {
        return this.canvases[sourceId].zoom;
    }
    return 0;
}

SmartSuiteDevice.prototype.getZoomIncrement = function () {
    return this.zoomIncrement;
}

SmartSuiteDevice.prototype.setZoomIncrement = function (value) {
    this.zoomIncrement = value;
}

SmartSuiteDevice.prototype.getMiniCanvasScale = function () {
    return this.miniCanvasScale;
}

SmartSuiteDevice.prototype.setMiniCanvasScale = function (value) {
    this.miniCanvasScale = value;
}

SmartSuiteDevice.prototype.setShowMovePoints = function (sourceId, bool) {
    this.canvases[sourceId].showMovePoints = bool;
}

SmartSuiteDevice.prototype.getCanvasMiniCoords = function (sourceId) {
    return {
        miniXStart: this.canvases[sourceId].miniXStart,
        miniYStart: this.canvases[sourceId].miniYStart,
        miniXEnd: this.canvases[sourceId].miniXEnd,
        miniYEnd: this.canvases[sourceId].miniYEnd
    };
}

SmartSuiteDevice.prototype.getCanvasCorner = function (sourceId) {
    //this returns a value 0-3 depending on the current corner the mini canvas player is in
    return this.canvases[sourceId].currentCorner;
}

SmartSuiteDevice.prototype.createCanvas = function (sourceId, draggable) {
    this.canvases[sourceId] = {
        height: 0,
        width: 0,
        x: 0,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        dragX: 0,
        dragY: 0,
        zoom: 0,
        draggable: draggable !== undefined ? draggable : false,
        pulsePositive: true,
        loading: false,
        pulseValue: 5,
        opacityPositive: false,
        opacity: 5,
        errorMessage: '',
        errorMessage2: '',
        showMiniCanvas: false,
        showMovePoints: false,
        currentCorner: 0    //0-top left 1-top right 2-bottom left 3-bottom right
    }
}

SmartSuiteDevice.prototype.resetVideoSize = function (sourceId, softResize, startDraggable) {
    let container = document.getElementById('video-container-' + sourceId);
    let video = document.getElementById('gamma-video-' + sourceId);
    let cHeight = container.offsetHeight;
    let cWidth = container.offsetWidth;
    let eHeight = video.videoHeight;
    let eWidth = video.videoWidth;
    let temp, tempOpacity, tempOpacityPositive, tempLoading, tempErrorMessage, tempErrorMessage2, tempShowMiniCanvas, tempCurrentCorner;
    if (this.canvases[sourceId]) {
        temp = this.canvases[sourceId].draggable || false;
        tempOpacity = this.canvases[sourceId].opacity || 5;
        tempOpacityPositive = this.canvases[sourceId].opacityPositive;
        tempLoading = this.canvases[sourceId].loading;
        tempErrorMessage = this.canvases[sourceId].errorMessage;
        tempErrorMessage2 = this.canvases[sourceId].errorMessage2;
        tempShowMiniCanvas = this.canvases[sourceId].showMiniCanvas;
        tempCurrentCorner = this.canvases[sourceId].currentCorner;
    }
    this.canvases[sourceId] = {
        height: eHeight === 0 ? cHeight : (cHeight / eHeight < cWidth / eWidth ? cHeight : ((cWidth / eWidth) * eHeight)),
        width: eWidth === 0 ? cWidth : (cHeight / eHeight < cWidth / eWidth ? ((cHeight  / eHeight) * eWidth) : cWidth),
        x: 0,
        y: 0,
        offsetX: 0,
        offsetY: 0,
        dragX: 0,
        dragY: 0,
        zoom: 0,
        draggable: (this.canvases[sourceId] !== undefined ? this.canvases[sourceId].draggable : (startDraggable === true ? true : (softResize === true ? temp : false))),
        pulsePositive: true,
        loading: softResize === true ? tempLoading : true,
        pulseValue: 5,
        opacityPositive: softResize === true ? tempOpacityPositive : true,
        opacity: softResize === true ? tempOpacity : 5,
        errorMessage: softResize === true ? tempErrorMessage : '',
        errorMessage2: softResize === true ? tempErrorMessage2 : '',
        showMiniCanvas: softResize === true ? tempShowMiniCanvas : false,
        currentCorner: softResize === true ? tempCurrentCorner : 0,
        showMovePoints: false
    }
}

SmartSuiteDevice.prototype.zoomIn = function (sourceId, containerX, containerY) {
    if (this.canvases[sourceId].draggable === true) {
        if (this.canvases[sourceId].zoom < 50) {
            //convert container coordinates into canvas coordinates
            let canvasX = this.canvases[sourceId].x - ((this.canvases[sourceId].x - containerX) / (1 + this.canvases[sourceId].zoom * this.zoomIncrement));
            let canvasY = this.canvases[sourceId].y - ((this.canvases[sourceId].y - containerY) / (1 + this.canvases[sourceId].zoom * this.zoomIncrement));
            //set canvas coordinates
            this.canvases[sourceId].x = this.canvases[sourceId].x - (this.canvases[sourceId].x - canvasX) / (1 + this.canvases[sourceId].zoom);
            this.canvases[sourceId].y = this.canvases[sourceId].y - (this.canvases[sourceId].y - canvasY) / (1 + this.canvases[sourceId].zoom);
            this.canvases[sourceId].zoom++;
        }
    }
}

SmartSuiteDevice.prototype.zoomOut = function (sourceId, containerX, containerY) {
    if (this.canvases[sourceId].draggable === true) {
        if (this.canvases[sourceId].zoom > 0) {
            //convert container coordinates into canvas coordinates
            let canvasX = this.canvases[sourceId].x - ((this.canvases[sourceId].x - containerX) / (1 + this.canvases[sourceId].zoom * this.zoomIncrement));
            let canvasY = this.canvases[sourceId].y - ((this.canvases[sourceId].y - containerY) / (1 + this.canvases[sourceId].zoom * this.zoomIncrement));
            //set canvas coordinates
            this.canvases[sourceId].x = this.canvases[sourceId].x - (this.canvases[sourceId].x - canvasX) / (1 + this.canvases[sourceId].zoom);
            this.canvases[sourceId].y = this.canvases[sourceId].y - (this.canvases[sourceId].y - canvasY) / (1 + this.canvases[sourceId].zoom);
            this.canvases[sourceId].zoom--;
        } else {
            this.resetVideoSize(sourceId, true);
        }
    }
}

SmartSuiteDevice.prototype.isDraggable = function (sourceId) {
    if (this.canvases[sourceId] !== undefined) {
        return this.canvases[sourceId].draggable;
    }
    return false;
}

SmartSuiteDevice.prototype.dragCanvas = function (sourceId, differenceX, differenceY) {
    if (this.canvases[sourceId].draggable === true) {
        let container = document.getElementById('video-container-' + sourceId);
        let ratio = 1 / this.zoomIncrement;
        let total = this.canvases[sourceId].zoom + ratio;
        let canvasPortionX = this.canvases[sourceId].x / total;
        let canvasPortionY = this.canvases[sourceId].y / total;
        let containerPortionX = container.offsetWidth / total;
        let containerPortionY = container.offsetHeight / total;

        let xMin = -(canvasPortionX * this.canvases[sourceId].zoom) + this.canvases[sourceId].offsetX;
        let yMin = -(canvasPortionY * this.canvases[sourceId].zoom) + this.canvases[sourceId].offsetY;
        let xMax = (containerPortionX * this.canvases[sourceId].zoom) + xMin - (this.canvases[sourceId].offsetX * 2);
        let yMax = (containerPortionY * this.canvases[sourceId].zoom) + yMin - (this.canvases[sourceId].offsetY * 2);

        //when there is an offset the max becomes zero for some reason so this corrects that
        if (this.canvases[sourceId].zoom === 0) {
            if (this.canvases[sourceId].offsetX !== 0) {
                xMax = xMin;
                xMin = -xMin;
            }
            if (this.canvases[sourceId].offsetY !== 0) {
                yMax = yMin;
                yMin = -yMin;
            }
        }
        if (this.canvases[sourceId].zoom > 0 || this.canvases[sourceId].offsetX > 0 || this.canvases[sourceId].offsetY > 0) {
            if (this.canvases[sourceId].dragX < xMin && differenceX > 0) {
                this.canvases[sourceId].dragX += differenceX / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
            if (this.canvases[sourceId].dragY < yMin && differenceY > 0) {
                this.canvases[sourceId].dragY += differenceY / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
            if (this.canvases[sourceId].dragX > xMax && differenceX < 0) {
                this.canvases[sourceId].dragX += differenceX / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
            if (this.canvases[sourceId].dragY > yMax && differenceY < 0) {
                this.canvases[sourceId].dragY += differenceY / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
            if (this.canvases[sourceId].dragX + (differenceX / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement))) < xMax && this.canvases[sourceId].dragX + (differenceX / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement))) > xMin) {
                this.canvases[sourceId].dragX += differenceX / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
            if (this.canvases[sourceId].dragY + (differenceY / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement))) < yMax && this.canvases[sourceId].dragY + (differenceY / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement))) > yMin) {
                this.canvases[sourceId].dragY += differenceY / (1 + (this.canvases[sourceId].zoom * this.zoomIncrement));
            }
        }
    }
}

SmartSuiteDevice.prototype.displayDecodedVideo = function (sourceId) {
    let video = document.getElementById('gamma-video-' + sourceId);
    let canvas = document.getElementById('video_canvas_' + sourceId);
    let image = document.getElementById('loading_image_' + sourceId);
    let container = document.getElementById('video-container-' + sourceId);
    let ctx = canvas.getContext('2d', {
        alpha: false,
        willReadFrequently: true
    });
    let smallerSize = Math.min(container.offsetWidth, container.offsetHeight);
    let zoomScale = 1 + (this.canvases[sourceId].zoom * this.zoomIncrement);
    video.play().catch(() => {});
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
    //these values account for half of the total empty space on either top|bottom or sides of video, not top&bottom or both sides
    let wCenter = this.canvases[sourceId].width > canvas.width - 3 ? 0 : (canvas.width - this.canvases[sourceId].width) / 2;
    let hCenter = this.canvases[sourceId].height > canvas.height - 3 ? 0 : (canvas.height - this.canvases[sourceId].height) / 2;

    if (this.canvases[sourceId].offsetX !== wCenter || this.canvases[sourceId].offsetY !== hCenter) {
        this.canvases[sourceId].offsetX = wCenter;
        this.canvases[sourceId].offsetY = hCenter;
    }

    //a video shouldn't have borders on both height and width so this needs to be rerun
    if ((wCenter !== 0 && hCenter !== 0) && (this.canvases[sourceId].x === 0 && this.canvases[sourceId].y === 0)) {
        this.resetVideoSize(sourceId, true);
    }
    //this is to catch the video if the default size of the video is larger than the container
    if ((video.videoWidth > canvas.width || video.videoHeight > canvas.height) && this.canvases[sourceId].width === canvas.width && this.canvases[sourceId].height === canvas.height && canvas.height !== 0 && canvas.width !== 0) {
        this.resetVideoSize(sourceId, true);
    }
    //this autocorrects the video size when switching between video wall and stream options view
    if (this.canvases[sourceId].zoom === 0 && canvas.height !== 0 && canvas.width !== 0 && (!valueCloseToEachOther(canvas.width, this.canvases[sourceId].width + (wCenter * 2), 3) || !valueCloseToEachOther(canvas.height, this.canvases[sourceId].height + (hCenter * 2), 3))) {
        this.resetVideoSize(sourceId, true);
    }
    //this checks the ratio of the canvas and compares it to the ratio of the video itself and makes sure it is similar and fitting the container
    if (video.videoWidth !== 0 & video.videoHeight !== 0 && !ratioCloseToEachOther(canvas.width, canvas.height, video.videoWidth, video.videoHeight)) {
        this.resetVideoSize(sourceId, true);
    }

    //console.log(canvas.width, canvas.height, this.canvases[sourceId].width, this.canvases[sourceId].height, video.videoWidth, video.videoHeight)

    //this handles the digital zoom feature for video
    ctx.translate(this.canvases[sourceId].x, this.canvases[sourceId].y);
    ctx.scale(zoomScale, zoomScale);
    ctx.translate(-this.canvases[sourceId].x, -this.canvases[sourceId].y);
    ctx.drawImage(video, wCenter - this.canvases[sourceId].dragX, hCenter - this.canvases[sourceId].dragY, this.canvases[sourceId].width, this.canvases[sourceId].height);

    let canvas2 = document.getElementById('video_canvas2_' + sourceId);
    let ctx2 = canvas2.getContext('2d', {willReadFrequently: true});
    if (this.canvases[sourceId].showMiniCanvas === true) {
        if (this.canvases[sourceId].zoom > 0) {
            canvas2.width = (canvas.width - (wCenter * 2)) / this.miniCanvasScale;
            canvas2.height = (canvas.height - (hCenter * 2)) / this.miniCanvasScale;
            ctx2.drawImage(video, 0, 0, canvas2.width, canvas2.height);
            ctx2.lineWidth = 2;
            ctx2.strokeStyle = '#F00';
            //visible window size of red box
            let visibleWidth = (canvas.width / this.miniCanvasScale / zoomScale);
            let visibleHeight = (canvas.height / this.miniCanvasScale / zoomScale);
            // xy coords on mini video player
            let xMini = (this.canvases[sourceId].x - wCenter) / this.miniCanvasScale;
            let yMini = (this.canvases[sourceId].y - hCenter) / this.miniCanvasScale;
            //starting coords of x & y for red box
            let xStart = (xMini - (xMini / zoomScale)) + ((this.canvases[sourceId].dragX - (wCenter / zoomScale)) / this.miniCanvasScale);
            let yStart = (yMini - (yMini / zoomScale)) + ((this.canvases[sourceId].dragY - (hCenter / zoomScale)) / this.miniCanvasScale);

            ctx2.rect(xStart, yStart, visibleWidth, visibleHeight);
            ctx2.stroke();
            if (this.canvases[sourceId].miniXStart !== xStart) {
                this.canvases[sourceId].miniXStart = xStart;
            }
            if (this.canvases[sourceId].miniYStart !== yStart) {
                this.canvases[sourceId].miniYStart = yStart;
            }
            if (this.canvases[sourceId].miniXEnd !== xStart + visibleWidth) {
                this.canvases[sourceId].miniXEnd = xStart + visibleWidth;
            }
            if (this.canvases[sourceId].miniYEnd !== yStart + visibleWidth) {
                this.canvases[sourceId].miniYEnd = yStart + visibleWidth;
            }

            //this space will be used to generate move points to have the mini window jump between corners
            /*if (this.canvases[sourceId].showMovePoints === true) {

            }*/

        } else {
            canvas2.width = 1;
            canvas2.height = 1;
            ctx2.clearRect(0, 0, canvas2.width - (wCenter / 2), canvas2.height - (hCenter / 2));
        }
    } else if (this.canvases[sourceId].showMiniCanvas === false) {
        this.canvases[sourceId].showMiniCanvas = null;
        canvas2.width = 1;
        canvas2.height = 1;
        ctx2.clearRect(0, 0, canvas2.width - (wCenter / 2), canvas2.height - (hCenter / 2));
    }

    //this draws the mtop logo while the video is loading
    if (this.canvases[sourceId].loading === true && container.offsetWidth > 0 && container.offsetHeight > 0) {
        ctx.arc(container.offsetWidth / 2, container.offsetHeight / 2, smallerSize * .3 - this.canvases[sourceId].opacity, 0, 2 * Math.PI, false);
        let grd = ctx.createRadialGradient(container.offsetWidth / 2, container.offsetHeight / 2, 3, container.offsetWidth / 2, container.offsetHeight / 2, smallerSize * .3 - this.canvases[sourceId].opacity);
        grd.addColorStop(0, "#FFFFFFA5");
        grd.addColorStop(.25, "#FFFFFF75");
        grd.addColorStop(.5, "#FFFFFF45");
        grd.addColorStop(.75, "#FFFFFF25");
        grd.addColorStop(.875, "#FFFFFF15");
        grd.addColorStop(1, "#FFFFFF05");
        if (this.canvases[sourceId].opacityPositive === true) {
            if (this.canvases[sourceId].opacity < (smallerSize * .3) - 1) {
                this.canvases[sourceId].opacity += 1;
            } else {
                this.canvases[sourceId].opacityPositive = !this.canvases[sourceId].opacityPositive;
            }
        } else {
            if (this.canvases[sourceId].opacity > 3) {
                this.canvases[sourceId].opacity -= 1;
            } else {
                this.canvases[sourceId].opacityPositive = !this.canvases[sourceId].opacityPositive;
            }
        }
        ctx.fillStyle = grd;
        ctx.fill();
        ctx.drawImage(image, container.offsetWidth / 2 - smallerSize * .3 / 2, container.offsetHeight / 2 - smallerSize * .3 / 2, smallerSize * .3, smallerSize * .3);
    }
    if (this.canvases[sourceId].errorMessage !== '') {
        ctx.font = Math.ceil(smallerSize / 25).toString() + 'px Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'red';
        ctx.fillText(this.canvases[sourceId].errorMessage, container.offsetWidth / 2, (container.offsetHeight / 1.95) + (smallerSize * .4 / 2));
    }
    if (this.canvases[sourceId].errorMessage2 !== '') {
        ctx.font = Math.ceil(smallerSize / 25).toString() + 'px Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'red';
        ctx.fillText(this.canvases[sourceId].errorMessage2, container.offsetWidth / 2, (container.offsetHeight / 2) + (smallerSize * .4 / 1.75) + Math.ceil(smallerSize / 25));
    }
    //pulsing bounding box
    /*if (this.canvases[sourceId].pulsePositive === true) {
        if (this.canvases[sourceId].pulseValue < 12) {
            this.canvases[sourceId].pulseValue++;
        } else {
            this.canvases[sourceId].pulsePositive = !this.canvases[sourceId].pulsePositive;
        }
    } else {
        if (this.canvases[sourceId].pulseValue > 2) {
            this.canvases[sourceId].pulseValue--;
        } else {
            this.canvases[sourceId].pulsePositive = !this.canvases[sourceId].pulsePositive;
        }
    }
    ctx.lineWidth = this.canvases[sourceId].pulseValue; */

    //this draws testing bounds when looking at sizing the canvas
    //ctx.lineWidth = 2;
    /*ctx.strokeStyle = '#F00'
    ctx.rect(wCenter - this.canvases[sourceId].dragX + 5, hCenter - this.canvases[sourceId].dragY+ 5, this.canvases[sourceId].width-5, this.canvases[sourceId].height-5);
    ctx.stroke();

    ctx.font = '10px Arial';
    ctx.strokeStyle = 'white';
    for (let i = 1; i < 15; i++) {
        for (let j = 1; j < 15; j++) {
            ctx.fillStyle = '#F00'
            ctx.fillRect(i*100, j*100, 10, 10);
            ctx.fillStyle = 'black';
            ctx.strokeText(`${i*100}, ${j*100}`, i*100, j*100);
            ctx.fillText(`${i*100}, ${j*100}`, i*100, j*100);
        }
    }
    for (let k = 1; k < 200; k++) {
        for (let l = 1; l < 150; l++) {
            ctx.fillStyle = '#0F0'
            ctx.fillRect(k*10, l*10, 1, 1);
        }
    }
    ctx.fillStyle = '#00F'
    ctx.fillRect(this.canvases[sourceId].width/2-10 + wCenter, this.canvases[sourceId].height/2-10 + hCenter, 20, 20);*/
}

function create_instance(device, sourceToken, dataChannel) {
    let instance;
    let sourceId = device.getDeviceId() + sourceToken + dataChannel;
    let insideRedBox = false;
    let mouseDown = false;
    let mouseCoords = {x: 0, y: 0};
    instance = document.createElement("div");
    instance.setAttribute("class", "d-flex justify-content-center align-items-center flex-fill video-container");
    instance.setAttribute("style", "background-color: #000000; min-height: 0;");
    instance.setAttribute('id', 'gamma-instance-' + device.getDeviceId() + sourceToken + dataChannel);
    let video = document.createElement('video');
    video.setAttribute('style', 'display: none;');
    video.autoplay = true;
    video.muted = true;
    video.setAttribute('id', 'gamma-video-' + device.getDeviceId() + sourceToken + dataChannel);
    instance.appendChild(video);
    video.addEventListener('error', function (e) {
        console.error(e);
    });

    //redrawing onto a canvas may be the best solution for true reverse playback
    //you draw onto the canvas after the image has been completed by the video player
    let canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'video_canvas_' + device.getDeviceId() + sourceToken + dataChannel);
    canvas.setAttribute("style", "position: relative; background-color: black");
    instance.appendChild(canvas);

    let image = document.createElement('img');
    image.src = 'img/mtoplogosmall.png';
    image.setAttribute('id', 'loading_image_' + device.getDeviceId() + sourceToken + dataChannel);
    image.setAttribute('style', 'position: absolute; display: none;');
    instance.appendChild(image);

    return instance;
}

function find_quality(streams, quality, device, sourceToken, dataChannel, archiveOnly) {
    if (archiveOnly === false && device.getDeviceModel() !== undefined && device.getDeviceModel().includes('4308')) {
        return streams.find(profile => profile.streamQuality === quality && profile.streamType === SmartSuiteArchive.streamTypes.Video && profile.dataChannel === dataChannel) ||
            streams.find(profile => profile.streamType === SmartSuiteArchive.streamTypes.Video && profile.dataChannel === dataChannel) ||
            streams.find(profile => profile.streamType === SmartSuiteArchive.streamTypes.Video) ||
            streams[0]
    } else if (quality !== null) {
        try {
            //try to find a live stream at the exact request quality || find any live stream matching only the source token || grab anything and try to play it
            return streams.find(profile => profile.streamQuality === quality && profile.streamType === SmartSuiteArchive.streamTypes.Video && profile.sourceToken === sourceToken) ||
                streams.findLast(profile => profile.streamType === SmartSuiteArchive.streamTypes.Video && profile.sourceToken === sourceToken) ||
                streams.find(profile => profile.streamType === SmartSuiteArchive.streamTypes.Video) ||
                streams[0];
        } catch (e) {
            if (device) {
                return {
                    streamServer: device.streamServer,
                    dataChannel: device.storeName,
                    hubName: 'RockfordOffice',
                    deviceSerial: device.deviceFriendlyName,
                    profileToken: 'profile0'
                }
            }
            return 0;
        }
    } else {
        let currentStream = this.stream.streamQuality;
        switch (currentStream) {
            case 2:
                return streams.find(profile => profile.streamQuality === 1 && profile.streamType === SmartSuiteArchive.streamTypes.Video);
            case 1:
                return streams.find(profile => profile.streamQuality === 0 && profile.streamType === SmartSuiteArchive.streamTypes.Video);
            case 0:
                break;
            default:
                return streams[0];
        }
    }
}

function get_quality_name(qualityValue) {
    switch (qualityValue) {
        case 0:
            return 'Low';
        case 1:
            return 'Medium';
        case 2:
            return 'High';
    }
}

function groupBy(array, key) {
    return array.reduce((result, item) => {
        (result[item[key]] = result[item[key]] || []).push(item);
        return result;
    }, {});
}

//this method takes a sourceId and returns an object consisting of deviceId, sourceToken, and dataChannel
function extractIdComponents(sourceId, deviceId) {
    let results = {
        deviceId: null,
        sourceToken: null,
        dataChannel: null
    };
    let startIndex = sourceId.indexOf(deviceId);
    let endIndex = startIndex + deviceId.length;
    results.deviceId = sourceId.substring(startIndex, endIndex);
    results.sourceToken = sourceId.substring(endIndex, endIndex+1);
    results.dataChannel = sourceId.substring(endIndex+1);
    return results;
}

function parseValue(value, sigFigs) {
    return parseFloat(value.toFixed(sigFigs !== undefined ? sigFigs : 3));
}

// this function compares two values and sees if they are within the acceptable tolerance
function valueCloseToEachOther (val1, val2, tolerance = 3, deadband) {
    if (deadband === undefined) {
        return Math.abs(val1 - val2) <= tolerance;
    } else {
        return (Math.abs(val1 - val2) <= tolerance && Math.abs(val1 - val2) >= deadband);
    }
}
// this function compares two ratios and sees if they are within the acceptable tolerance
function ratioCloseToEachOther (w1, h1, w2, h2, tolerance = 2) {
    const ratio1 = w1 / h1;
    const ratio2 = w2 / h2;
    const difference = Math.abs(ratio1 - ratio2);
    return difference <= tolerance;
}

export default SmartSuiteDevice;