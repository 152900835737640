//import authStore from "@/store/authStore";

;(function () {
    var vueAuthImage = {};
    var axios = typeof require === 'function'
        ? require('axios')
        : window.Axios;
   var authStore = require('@/store/authStore').default;
   var PubSub = require('pubsub-js');
    if (!axios) {
        throw new Error('[vue-auth-image] cannot locate Axios');
    }

    function setImgSrc(el, binding) {
        if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
            var imageUrl = binding.value;
            axios({
                method: 'get',
                url: imageUrl,
                responseType: 'arraybuffer',
                headers: {
                    'Authorization': 'Bearer ' + authStore.getters.getUserManager.getAccessToken()
                }
            }).then(function (resp) {
                var mimeType = resp.headers['content-type'].toLowerCase();
                var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
                el.src = 'data:' + mimeType + ';base64,' + imgBase64;
                PubSub.publish('riscImageLoad');
            }).catch((function () {
                el.src = imageUrl;
                PubSub.publish('riscImageLoad');
            }));
        }
    }

    vueAuthImage.install = function (Vue) {
        Vue.directive('auth-image', {
            bind: function (el, binding) {
                setImgSrc(el, binding);
            },
            componentUpdated: function (el, binding) {
                setImgSrc(el, binding);
            }
        });
    };

    if (typeof exports == 'object') {
        module.exports = vueAuthImage;
    } else if (typeof define == 'function' && define.amd) {
        define([], function () {
            return vueAuthImage;
        });
    } else if (window.Vue) {
        window.VueAuthImage = vueAuthImage;
        Vue.use(vueAuthImage);
    }
})();
