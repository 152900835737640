import gammaStore from "@/store/gammaStore";
import authStore from "@/store/authStore";

function SmartSuiteLivestream(device, tenantId) {
    this.gamma = null;
    this.device = device;
    this.tenantId = tenantId ? tenantId : this.device.getTenantId();
}


SmartSuiteLivestream.prototype.connect = async function (newConnection) {
    this.gamma = await gammaStore.dispatch('getLiveStreamhub', {
        tenantId: this.tenantId,
        newConnection: newConnection === true
    });
}

SmartSuiteLivestream.prototype.close = async function () {
    await gammaStore.dispatch('closeLiveStreamhub', this.tenantId);
}

SmartSuiteLivestream.prototype.getLiveStreamRequest = async function (stream, callback, count = 0) {
    await this.checkConnection();
    let request = {
        RequestedStream: stream,
        UserName: await authStore.dispatch('getUserName'),
        TimeStamp: Date.now()
    };
    let response = undefined;
    try {
        response = await this.gamma.sendMessage('CacheLiveStreamRequestAsync', request).catch(() => {
            setTimeout(async () => {
                await this.getLiveStreamRequest(stream, callback);
            }, 3000);
            return undefined;
        });
    } catch (e) {}

     if (response !== undefined) {
         callback(response);
     } else if (count === 3) {
         callback(undefined);
     } else {
         setTimeout(async () => {
             count++;
             await this.getLiveStreamRequest(stream, callback, count);
         }, 3000);
     }
}

SmartSuiteLivestream.prototype.refresh = async function () {
    await this.gamma.refresh(true);
}

SmartSuiteLivestream.prototype.addSourceListener = async function (sourceId) {
    this.gamma.sendMessage('AddSourceListener', sourceId);
}

SmartSuiteLivestream.prototype.removeSourceListener = async function (sourceId) {
    this.gamma.sendMessage('RemoveSourceListener', sourceId);
}

SmartSuiteLivestream.prototype.setSupportMessageListener = async function (callback) {
    await gammaStore.dispatch('setLiveStreamhubListener', {
        _id: this.tenantId, event: 'supportmessage', callback: (event) => {
            callback(event);
        }
    });
}

SmartSuiteLivestream.prototype.checkConnection = async function () {
    if (!this.gamma.isConnected() && !this.gamma.isConnecting()) {
        await this.connect();
    }
}

SmartSuiteLivestream.prototype.isConnected = function () {
    if (this.gamma && this.gamma.isConnected()) {
        return true;
    }
    return false;
}

export default SmartSuiteLivestream;