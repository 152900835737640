import gammaStore from "../../store/gammaStore";

const AddUser = 0;
const RemoveUser = 1;
const AddCredentials = 2;
const ManageDevice = 3;
const StartArchive = 4;
const StopArchive = 5;
const SetDeviceName = 6;
const SetStreamName = 7;
const RebootDevice = 8;

function SmartSuiteDevicehub(device) {
    this.gamma = null;
    this.device = device;
}

SmartSuiteDevicehub.prototype.connect = async function () {
    this.gamma = await gammaStore.dispatch('getDevicehub', this.device.getTenantId(),);
}

SmartSuiteDevicehub.prototype.rebootDevice = async function () {
    let message = {
        commandRequest: RebootDevice,
        deviceSerialNumber: this.device.getSerialNumber(),
        dataChannel: this.device.getHubName() + '_command',
        hubCommandChannel: this.device.getHubName() + '_command'
    };
    this.gamma.sendMessage('SendUserMessage', message);
}

export default SmartSuiteDevicehub