// Views - Components
const Cards = () => import('@/views/template_files/base/Cards');
const Switches = () => import('@/views/template_files/base/Switches');
const Tabs = () => import('@/views/template_files/base/Tabs');
const Breadcrumbs = () => import('@/views/template_files/base/Breadcrumbs');
const Carousels = () => import('@/views/template_files/base/Carousels');
const Collapses = () => import('@/views/template_files/base/Collapses');
const Jumbotrons = () => import('@/views/template_files/base/Jumbotrons');
const ListGroups = () => import('@/views/template_files/base/ListGroups');
const Navs = () => import('@/views/template_files/base/Navs');
const Navbars = () => import('@/views/template_files/base/Navbars');
const Paginations = () => import('@/views/template_files/base/Paginations');
const Popovers = () => import('@/views/template_files/base/Popovers');
const ProgressBars = () => import('@/views/template_files/base/ProgressBars');
const Tooltips = () => import('@/views/template_files/base/Tooltips');

// Views - Buttons
const StandardButtons = () => import('@/views/template_files/buttons/StandardButtons');
const ButtonGroups = () => import('@/views/template_files/buttons/ButtonGroups');
const Dropdowns = () => import('@/views/template_files/buttons/Dropdowns');
const BrandButtons = () => import('@/views/template_files/buttons/BrandButtons');

// Views - Editors
const TextEditors = () => import('@/views/template_files/editors/TextEditors');
const CodeEditors = () => import('@/views/template_files/editors/CodeEditors');

// Views - Forms
const BasicForms = () => import('@/views/template_files/forms/BasicForms');
const AdvancedForms = () => import('@/views/template_files/forms/AdvancedForms');
const ValidationForms = () => import('@/views/template_files/forms/ValidationForms');

// Views GoogleMaps
const GoogleMaps = () => import('@/views/GoogleMaps');

// Views - Icons
const Flags = () => import('@/views/template_files/icons/Flags');
const FontAwesome = () => import('@/views/template_files/icons/FontAwesome');
const SimpleLineIcons = () => import('@/views/template_files/icons/SimpleLineIcons');
const CoreUIIcons = () => import('@/views/template_files/icons/CoreUIIcons');

// Views - Notifications
const Alerts = () => import('@/views/template_files/notifications/Alerts');
const Badges = () => import('@/views/template_files/notifications/Badges');
const Modals = () => import('@/views/template_files/notifications/Modals');
const Toastr = () => import('@/views/template_files/notifications/Toastr');

// Views - Tables
const Tables = () => import('@/views/template_files/tables/Tables');
const DataTable = () => import('@/views/template_files/tables/DataTable');

const Colors = () => import('@/views/template_files/theme/Colors');
const Typography = () => import('@/views/template_files/theme/Typography');

const Charts = () => import('@/views/template_files/Charts');
const Widgets = () => import('@/views/template_files/Widgets');

// Plugins
const Draggable = () => import('@/views/template_files/plugins/Draggable');
const Calendar = () => import('@/views/template_files/plugins/Calendar');
const Spinners = () => import('@/views/template_files/plugins/Spinners');

// Views - UI Kits
const Invoice = () => import('@/views/template_files/apps/invoicing/Invoice');
const Compose = () => import('@/views/template_files/apps/email/Compose');
const Inbox = () => import('@/views/template_files/apps/email/Inbox');
const Message = () => import('@/views/template_files/apps/email/Message');

export default [
    {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'colors',
                name: 'Colors',
                component: Colors
            },
            {
                path: 'typography',
                name: 'Typography',
                component: Typography
            }
        ]
    },
    {
        path: 'charts',
        name: 'Charts',
        component: Charts
    },
    {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
    },
    {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'breadcrumbs',
                name: 'Breadcrumbs',
                component: Breadcrumbs
            },
            {
                path: 'cards',
                name: 'Cards',
                component: Cards
            },
            {
                path: 'carousels',
                name: 'Carousels',
                component: Carousels
            },
            {
                path: 'collapses',
                name: 'Collapses',
                component: Collapses
            },
            {
                path: 'jumbotrons',
                name: 'Jumbotrons',
                component: Jumbotrons
            },
            {
                path: 'list-groups',
                name: 'List Groups',
                component: ListGroups
            },
            {
                path: 'navs',
                name: 'Navs',
                component: Navs
            },
            {
                path: 'navbars',
                name: 'Navbars',
                component: Navbars
            },
            {
                path: 'paginations',
                name: 'Paginations',
                component: Paginations
            },
            {
                path: 'popovers',
                name: 'Popovers',
                component: Popovers
            },
            {
                path: 'progress-bars',
                name: 'Progress Bars',
                component: ProgressBars
            },
            {
                path: 'switches',
                name: 'Switches',
                component: Switches
            },
            {
                path: 'tabs',
                name: 'Tabs',
                component: Tabs
            },
            {
                path: 'tooltips',
                name: 'Tooltips',
                component: Tooltips
            }
        ]
    },
    {
        path: 'buttons',
        redirect: '/buttons/standard-buttons',
        name: 'Buttons',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'standard-buttons',
                name: 'Standard Buttons',
                component: StandardButtons
            },
            {
                path: 'button-groups',
                name: 'Button Groups',
                component: ButtonGroups
            },
            {
                path: 'dropdowns',
                name: 'Dropdowns',
                component: Dropdowns
            },
            {
                path: 'brand-buttons',
                name: 'Brand Buttons',
                component: BrandButtons
            }
        ]
    },
    {
        path: 'editors',
        redirect: '/editors/text-editors',
        name: 'Editors',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'text-editors',
                name: 'Text Editors',
                component: TextEditors
            },
            {
                path: 'code-editors',
                name: 'Code Editors',
                component: CodeEditors
            }
        ]
    },
    {
        path: 'forms',
        redirect: '/forms/basic-forms',
        name: 'Forms',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'basic-forms',
                name: 'Basic Forms',
                component: BasicForms
            },
            {
                path: 'advanced-forms',
                name: 'Advanced Forms',
                component: AdvancedForms
            },
            {
                path: 'validation-forms',
                name: 'Form Validation',
                component: ValidationForms
            }
        ]
    },
    {
        path: 'icons',
        redirect: '/icons/font-awesome',
        name: 'Icons',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'coreui-icons',
                name: 'CoreUI Icons',
                component: CoreUIIcons
            },
            {
                path: 'flags',
                name: 'Flags',
                component: Flags
            },
            {
                path: 'font-awesome',
                name: 'Font Awesome',
                component: FontAwesome
            },
            {
                path: 'simple-line-icons',
                name: 'Simple Line Icons',
                component: SimpleLineIcons
            }
        ]
    },
    {
        path: 'notifications',
        redirect: '/notifications/alerts',
        name: 'Template Notifications',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'alerts',
                name: 'Alerts',
                component: Alerts
            },
            {
                path: 'badges',
                name: 'Badges',
                component: Badges
            },
            {
                path: 'modals',
                name: 'Modals',
                component: Modals
            },
            {
                path: 'toastr',
                name: 'Toastr',
                component: Toastr
            }
        ]
    },
    {
        path: 'plugins',
        redirect: '/plugins/draggable',
        name: 'Plugins',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'draggable',
                name: 'Draggable Cards',
                component: Draggable
            },
            {
                path: 'calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: 'spinners',
                name: 'Spinners',
                component: Spinners
            },
        ]
    },
    {
        path: 'tables',
        redirect: '/tables/tables',
        name: 'Tables',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'tables',
                name: 'Simple Tables',
                component: Tables
            },
            {
                path: 'data-table',
                name: 'Data Table',
                component: DataTable
            },
        ]
    },
    {
        path: 'apps',
        name: 'Apps',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'invoicing',
                redirect: '/apps/invoicing/invoice',
                name: 'Invoicing',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'invoice',
                        name: 'Invoice',
                        component: Invoice
                    }
                ]
            },
            {
                path: 'email',
                redirect: '/apps/email/inbox',
                name: 'Email',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'compose',
                        name: 'Compose',
                        component: Compose
                    },
                    {
                        path: 'inbox',
                        name: 'Inbox',
                        component: Inbox
                    },
                    {
                        path: 'message',
                        name: 'Message',
                        component: Message
                    }
                ]
            }
        ]
    }
];